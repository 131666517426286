import axios from "axios";

// const token = localStorage.getItem("jwtToken");
const api = axios.create({
  baseURL: "https://www.findu.online/api",
  headers: {
    "Content-Type": "application/json",
    // 'Authorization': `Bearer ${token}`
  },
  withCredentials: true,
});

export default api;
