import React, { useState, useEffect } from "react";
import style from "../styles/modules/SearchPage.module.css";
import TagSelector from "./TagSelector";

function FilterMenu({ searchType, formData, setFormData, onFormDataChange }) {
  const [newTag, setNewTag] = useState("");

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const updatedValue = type === "checkbox" ? checked : value;

    // Ensure other fields in formData are not overwritten
    const updatedData = {
      ...formData,
      [name]: updatedValue,
    };

    onFormDataChange(updatedData); // Call parent function to update state
  };

  const handleAddProjectTag = () => {
    if (newTag.trim() && formData.projectTags.length < 10) {
      const updatedTags = [...formData.projectTags, newTag.trim()];
      onFormDataChange({ ...formData, projectTags: updatedTags });
      setNewTag("");
    }
  };

  const handleRemoveProjectTag = (tagToRemove) => {
    const updatedTags = formData.projectTags.filter(
      (tag) => tag !== tagToRemove
    );
    onFormDataChange({ ...formData, projectTags: updatedTags });
  };

  const handleAddUserTag = () => {
    if (newTag.trim() && formData.userTags.length < 10) {
      const updatedTags = [...formData.userTags, newTag.trim()];
      onFormDataChange({ ...formData, userTags: updatedTags });
      setNewTag("");
    }
  };

  const handleRemoveUserTag = (tagToRemove) => {
    const updatedTags = formData.userTags.filter((tag) => tag !== tagToRemove);
    onFormDataChange({ ...formData, userTags: updatedTags });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault(); // Prevent page refresh
  // };

  if (searchType === "projects") {
    return (
      <div className={style.filterMenu}>
        <form>
          <div className={style.filterContents}>
          {/* Project Status */}
          <div className={style.projectStatus}>
            <h3>Project Status:</h3>

            <div className={style.toggleContainer}>
              <label className={style.toggleSwitch}>
                <input
                  type="checkbox"
                  name="statusYes"
                  checked={formData.statusYes}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    console.log("isChecked");
                    console.log(isChecked);
                    onFormDataChange({
                      ...formData,
                      statusYes: isChecked,
                      statusNo: false,
                    });
                  }}
                />
                <span className={style.slider}></span>
              </label>
              <span className={style.toggleLabel}>Show open teams</span>
            </div>
          </div>

          {/* Meeting Type */}
          <div className={style.meetingType}>
            <h3>Meeting Type:</h3>
            <div className={style.checkboxInput}>
              <input
                type="checkbox"
                id="hybridBox"
                name="hybrid"
                checked={formData.hybrid}
                onChange={handleChange}
              />
              <label htmlFor="hybrid">Hybrid</label>
            </div>
            <div className={style.checkboxInput}>
              <input
                type="checkbox"
                id="virtualBox"
                name="virtual"
                checked={formData.virtual}
                onChange={handleChange}
              />
              <label htmlFor="virtual">Virtual</label>
            </div>
            <div className={style.checkboxInput}>
              <input
                type="checkbox"
                id="inPersonBox"
                name="inPerson"
                checked={formData.inPerson}
                onChange={handleChange}
              />
              <label htmlFor="inPerson">In-Person</label>
            </div>
          </div>

          {/* Team Size */}
          <div className={style.teamSize}>
            <h3>Team Size:</h3>
            <div className={style.minMaxInput}>
              <div className={style.numberInput}>
                <label htmlFor="minMembers">Min</label>
                <input
                  type="number"
                  name="min"
                  value={formData.min}
                  onChange={handleChange}
                  min={0}
                  max={formData.max || 100}
                />
              </div>
              <div className={style.numberInput}>
                <label htmlFor="maxMembers">Max</label>
                <input
                  type="number"
                  name="max"
                  value={formData.max}
                  onChange={handleChange}
                  min={formData.min || 0}
                  max={100}
                />
              </div>
            </div>
          </div>
          <div className={style.tagInput}>
            <TagSelector
              formData={formData}
              setFormData={setFormData}
              formDataField={"projectTags"}
            />
          </div>
          </div>
        </form>
      </div>
    );
  } else if (searchType === "users") {
    return (
      <div className={style.filterMenu}>
        <form>
          {/* Tags */}
          <div className={style.addTags}>
            <TagSelector
              formData={formData}
              setFormData={setFormData}
              formDataField={"userTags"}
            />
          </div>
        </form>
      </div>
    );
  }

  return <p>Invalid search type</p>;
}
export default FilterMenu;
