import React from "react";

const IconChecks = ({ size = 20, style = {} }) => (
  <svg
    width={size}
    height={size}
    style={style}
    viewBox="0 0 37 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.75 16.0816V17.5C34.748 20.8244 33.6133 24.0592 31.5152 26.7219C29.417 29.3846 26.4678 31.3325 23.1075 32.2751C19.7471 33.2177 16.1555 33.1045 12.8685 31.9524C9.58148 30.8003 6.77506 28.671 4.86781 25.8821C2.96056 23.0932 2.05466 19.7941 2.28522 16.4768C2.51579 13.1595 3.87046 10.0018 6.1472 7.47461C8.42395 4.94743 11.5008 3.18617 14.9188 2.45351C18.3368 1.72086 21.9129 2.05606 25.1138 3.40912M34.75 5.16662L18.5 20.5987L13.625 15.9737"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconChecks;
