import React, { useEffect, useState } from "react";
import api from "../config/AxiosConfig";
import style from "../styles/modules/ProjectPage.module.css";
import IconLocation from "./IconLocation";

function EventCard({ id }) {
    const [eventInfo, setEventInfo] = useState([]);
    useEffect(() => {
      api
        .get(`/events/${id}`)
        .then((response) => {
            setEventInfo(response.data);
        })
        .catch((error) => {
          console.error("There was an error fetching the user data!", error);
        });
    }, [id]);
    const url = "/events/" + id;
    const formatDate = (isoString) => {
        if (!isoString) return "No date set";
        const date = new Date(isoString);
        return date.toLocaleString('en-US', {
            month: 'long', 
            day: 'numeric', 
            year: 'numeric', 
            hour: '2-digit', 
            minute: '2-digit',
            hour12: true
        });
    };
    console.log(eventInfo);
    return (
      <div class={style.memberCard}>
        <a href={url}>{eventInfo.title} </a>
        <div class={style.memberCardInfo}>
          <div class={style.memberDetails}>
            <p>Starts: {formatDate(eventInfo.startDateTime)}</p>
            <p>Ends: {formatDate(eventInfo.endDateTime)}</p>
            <h4><IconLocation/> {eventInfo.location}</h4>
          </div>
        </div>
      </div>
    );
  }export default EventCard;