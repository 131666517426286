import ProjectPreview from "./ProjectPreview";

function ProjectsSearchResults({ responses, images }) {
    // if (!responses || responses.length === 0) {
    //     return <p>No projects found for your search.</p>;
    // }
    // console.log("project search result");
    // console.log("Image Map:", images);
    return (
        <>
            {responses.map((project, i) => (
                <ProjectPreview
                    key={i}
                    title={project.title}
                    description={project.description}
                    id={project.id}
                    image={project.imageId}
                    // image={images[project.imageId]}
                    status={project.status}
                    meetingFormat={project.meetingFormat}
                    tags={project.tags}
                    users={project.usersId}
                />
            ))}
        </>
    );
  }
  export default ProjectsSearchResults;