import React, { useState } from "react";
import ShowPasswordButton from "../components/ShowPasswordButton";
import style from "../styles/modules/AuthPages.module.css";
import logo from "../assets/IconWhite.png";
import api from "../config/AxiosConfig";
import { useFormContext } from "../context/FormContext";
import { useNavigate } from "react-router-dom";
import { useUser } from "../context/UserContext";

function SignInPage() {
  const navigate = useNavigate();
  const { markFormAsSubmitted } = useFormContext();
  const { user, setUser } = useUser();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = [];
    if (
      !(
        formData.email.toLowerCase().endsWith("@utah.edu") ||
        formData.email.toLowerCase().endsWith("@umail.utah.edu")
      )
    ) {
      errors.push("Email must be a University of Utah email address.");
    }
    if (formData.password.length > 72) {
      errors.push("Password is too long!");
    }

    if (errors.length > 0) {
      alert(errors.join("\n"));
      return;
    }

    try {
      const response = await api.post("public/auth/login", {
        email: formData.email,
        password: formData.password,
      });
      if (response.status === 200 || response.status === 201) {
        const userData = response.data;
        const profileCompleted = userData.profileCompleted;
        markFormAsSubmitted("registerForm");
        setUser(userData);
        console.log("Login successful.");
        const params = new URLSearchParams(window.location.search);
        const redirectPath = params.get("redirect") || "/search";
        if (profileCompleted) {
          window.location.href = redirectPath;
        } else {
          navigate(`/createuser?redirect=${encodeURIComponent(redirectPath)}`);
        }
      }
    } catch (e) {
      console.log(e);
      const errorMessage =
        e.response?.data || "An error occurred during sign-in.";
      const statusCode = e.response?.status;
      alert(`Error ${statusCode}: ${errorMessage}`);
    }
  };
  return (
    <>
      {/* TODO: implement university SSO */}
      {/* TODO: create paths for forgot username/password */}
      <div class={style.container}>
        <img class={style.logoImage} src={logo} alt="FindU Logo"></img>
        <form class={style.entryArea} onSubmit={handleSubmit}>
          <h1 class={style.formTitle}>Sign In</h1>
          <table class={style.inputTable}>
            <tbody class={style.inputTableBody}>
              <tr class={style.columnTr}>
                <td>
                  <label class={style.fieldLabel} for="email">
                    Enter your University of Utah email:
                  </label>
                </td>
                <td>
                  <input
                    class={style.formInput}
                    id="email"
                    name="email"
                    placeholder="u0000000@utah.edu"
                    autoFocus
                    required
                    value={formData.email}
                    onChange={handleChange}
                  />
                </td>
                {/* <td><a class={style.recoveryLink} href="/">Forgot username?</a></td> */}
              </tr>
              <tr class={style.columnTr}>
                <td>
                  <label class={style.fieldLabel} for="password">
                    Password:
                  </label>
                </td>
                <td class={style.rowTd}>
                  <input
                    class={style.formInput}
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Enter your password"
                    required
                    value={formData.password}
                    onChange={handleChange}
                  />
                  <ShowPasswordButton id="password" />
                </td>
                <td>
                  <a class={style.recoveryLink} href="/forgot-password">
                    Forgot password?
                  </a>
                </td>
              </tr>
            </tbody>
            <tfoot class={style.footer}>
              <td>
                <button class={style.submitButton}>Continue</button>
              </td>
            </tfoot>
          </table>
          <p>
            New to FindU?{" "}
            <a
              className={style.redirectLink}
              href={(() => {
                const params = new URLSearchParams(window.location.search);
                const redirectPath = params.get("redirect");
                return redirectPath
                  ? `/register/?redirect=${encodeURIComponent(redirectPath)}`
                  : "/register/";
              })()}
            >
              Register here.
            </a>
          </p>
        </form>
      </div>
      <div class={style.background}></div>
    </>
  );
}

export default SignInPage;
