import style from "../styles/modules/NotFoundPage.module.css"

function NotFound() {
    return (
        <div class={style.mainContainer}>
            <h1>404</h1>
            <h2>This page has been deleted, relocated, or never existed!</h2>
            <a href="/search">&#x2190; Get back to working on the next big thing</a>
        </div>
    );
  }
export default NotFound;