import React, { createContext, useState, useEffect } from "react";
import api from "../config/AxiosConfig";
import { useTheme } from "./ThemeContext";

// Create a context with a default value of null for the user
const UserContext = createContext(null);

// Create a provider component that will wrap your app
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const { theme, setTheme } = useTheme();

  // Fetch the current user once when the app is loaded
  useEffect(() => {
    // console.log("FROM USER CONTEXT: Current user: ", user)
    const fetchCurrentUser = async () => {
      try {
        const response = await api.get("public/auth/getCurrentUser", {
          withCredentials: true,
        });
        if (response.status === 200 || response.status === 201) {
          setUser(response.data);
          //console.log("THEME: " + response.data.theme);
          if (response.data.settings.themePreference) {
            setTheme(response.data.settings.themePreference);
          } else {
            setTheme("system");
          }
        }
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    fetchCurrentUser();
  }, [setTheme]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

// Export the context and the hook to use the context
export const useUser = () => {
  return React.useContext(UserContext);
};
