import React, { useEffect, useState } from "react";
import style from "../styles/modules/SelectOptions.module.css";
import api from "../config/AxiosConfig.js";

function ProjectOption({ id }) {
  const [projectInfo, setProjectInfo] = useState([]);
  useEffect(() => {
    api
      .get(`/projects/${id}`)
      .then((response) => {
        setProjectInfo(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the project data!", error);
      });
  }, []);
  return <option value={id}>{projectInfo.title}</option>;
}

function GenericOption({ value, name }) {
  if (value == "disabled") {
    return (
      <option value="" disabled>
        {name}
      </option>
    );
  }
  return <option value={value}>{name}</option>;
}

function SelectOptions({
  name,
  selected,
  id,
  onChange,
  options,
  type = "none",
  value,
  displayedOptions,
  includingDisplayed = false,
}) {
  //console.log(displayedOptions);
  //console.log(options);
  //console.log(typeof(options));

  return (
    <>
      <div className={style.select}>
        <select
          name={name}
          selected={selected}
          id={id}
          onChange={onChange}
          value={value}
        >
          {options.map((option, i) => (
            <>
              {type == "project" ? (
                <ProjectOption id={option} />
              ) : includingDisplayed ? (
                <GenericOption
                  value={option}
                  name={displayedOptions.get(option)}
                />
              ) : (
                <GenericOption value={option} name={option} />
              )}
            </>
          ))}
        </select>
      </div>
    </>
  );
}

export default SelectOptions;
