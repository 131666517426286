import React, { useState } from 'react';
import showingImage from "../assets/eye.png"
import hiddenImage from "../assets/eye-closed.png"

const ShowPasswordButton = ({ id }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    const inputElement = document.getElementById(id);
    if (inputElement) {
      if (inputElement.type === 'password') {
        inputElement.type = 'text';
        setShowPassword(true);
      } else {
        inputElement.type = 'password';
        setShowPassword(false);
      }
    }
  };

  return (
    <button type="button" onClick={togglePasswordVisibility} style={{ background: 'none', border: 'none' }}>
      <img src={showPassword ? showingImage : hiddenImage} alt={showPassword ? 'Hide' : 'Show'} />
    </button>
  );
};

export default ShowPasswordButton;