import React, { useState, useContext, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import logoSmall from "../assets/IconWhite.png";
import logo from "../assets/LogoWord.png";
import logoDark from "../assets/FindULogoDarkMode.png";
import style from "../styles/modules/TopNav.module.css";
import Image from "./Image";
import api from "../config/AxiosConfig";
import { useUser } from "../context/UserContext";
import Badge from "./Badge";
import NotificationIcon from "./NotificationIcon";
import defaultProfilePhoto from "../assets/default_profile_photo.jpg";
import { useTheme } from "../context/ThemeContext";

function NavItem({ to, name }) {
  return <NavLink to={to}>{name}</NavLink>;
}

function TopNav() {
  const [query, setQuery] = useState("");
  const [searchType, setSearchType] = useState("");
  const location = useLocation(); //get url
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const { theme, setTheme } = useTheme();
  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const getQueryParam = (name) => {
    return new URLSearchParams(location.search).get(name);
  };

  useEffect(() => {
    const searchTypeParam = getQueryParam("searchType") || "projects";
    const queryParam = getQueryParam("query") || "";

    setSearchType(searchTypeParam);
    setQuery(queryParam);
  }, [location.search]);

  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    navigate(
      `/search?query=${encodeURIComponent(
        query
      )}&searchType=${encodeURIComponent(searchType)}`
    ); // Add searchType=projects
  };

  const handleUserSearch = (e) => {
    //console.log("searching users");
    // setSearchType("users");
    navigate(`/search?query=${encodeURIComponent(query)}=searchType=users}`); // Add searchType=projects
  };
  const handleProjectSearch = (e) => {
    //console.log("searching projects");
    // setSearchType("projects");
    navigate(`/search?query=${encodeURIComponent(query)}=searchType=projects`); // Add searchType=projects
  };

  const logOut = async (e) => {
    //console.log("Initiate logout.");
    try {
      // Clear the JWT cookie by making a request to the backend logout endpoint
      await api.post("/public/auth/logout", { withCredentials: true });
      setUser(null);
      localStorage.setItem("theme", "system");
      setTheme("system");
      navigate("/search");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const HandleEdit = (e) => {
    navigate(`/users/${user.id}/edit`);
  };

  const HandleSettings = (e) => {
    navigate(`/users/${user.id}/settings`);
  };

  const expandMenu = (e) => {
    //console.log("clicked", Date.now());
    var x = document.getElementById("menuContent");
    //console.log(x);
    if (!x) {
      return;
    }
    //console.log(x.className);
    if (x.className === "Collapsed") {
      x.className = "Expanded";
    } else {
      x.className = "Collapsed";
    }
  };

  return (
    <nav className={style.NavBar}>
      <div className={style.NavItemsWide}>
        <div className={style.tooltip}>
          <a className={style.LogoLink} href="/">
            <picture>
              {theme === "system" ? (
                <>
                  <source srcset={logo} media="(prefers-color-scheme: light)" />
                  <source
                    srcset={logoDark}
                    media="(prefers-color-scheme: dark)"
                  />
                  <img
                    src={logo}
                    alt="logo link to home"
                    style={{ width: "140px", height: "42px" }}
                  />
                </>
              ) : theme === "dark" ? (
                <>
                  <source
                    srcset={logoDark}
                    media="(prefers-color-scheme: dark)"
                  />
                  <img
                    src={logo}
                    alt="logo link to home"
                    style={{ width: "140px", height: "42px" }}
                  />
                </>
              ) : (
                <>
                  <source srcset={logo} media="(prefers-color-scheme: dark)" />
                  <img
                    src={logo}
                    alt="logo link to home"
                    style={{ width: "140px", height: "42px" }}
                  />
                </>
              )}
            </picture>
          </a>
          <span className={style.tooltiptext}>Home Page</span>
        </div>
        <div className={style.tooltip}>
          <a href="/search">
            <svg
              width="30"
              height="30"
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23 3.5C12.2304 3.5 3.5 12.2304 3.5 23C3.5 33.7696 12.2304 42.5 23 42.5C33.7696 42.5 42.5 33.7696 42.5 23C42.5 12.2304 33.7696 3.5 23 3.5ZM0.5 23C0.5 10.5736 10.5736 0.5 23 0.5C35.4264 0.5 45.5 10.5736 45.5 23C45.5 35.4264 35.4264 45.5 23 45.5C10.5736 45.5 0.5 35.4264 0.5 23ZM30.6613 8.65362C31.1747 8.90578 31.5 9.42799 31.5 10V25.9577C31.5 26.398 31.3066 26.816 30.9711 27.101L17.9711 38.1432C17.5255 38.5217 16.9007 38.6068 16.3702 38.3614C15.8396 38.1159 15.5 37.5846 15.5 37V20.0423C15.5 19.5776 15.7153 19.1392 16.083 18.8552L29.083 8.81293C29.5357 8.46325 30.1478 8.40146 30.6613 8.65362ZM18.5 20.779V33.7578L28.5 25.2638V13.0541L18.5 20.779ZM23 21.5C23.8284 21.5 24.5 22.1716 24.5 23C24.5 23.8284 23.8284 24.5 23 24.5C22.1716 24.5 21.5 23.8284 21.5 23C21.5 22.1716 22.1716 21.5 23 21.5Z"
                fill="currentColor"
              />
            </svg>
          </a>
          <span className={style.tooltiptext}>Explore</span>
        </div>
        <form className={style.searchForm} onSubmit={handleFormSubmit}>
          <div className={style.searchBar}>
            <input
              className={style.searchInput}
              name="query"
              placeholder="Search projects and peers"
              value={query}
              onChange={handleQueryChange}
            />
            <div className={style.tooltip}>
              <a
                className={style.searchLink}
                href={`/search?query=${encodeURIComponent(
                  query
                )}&searchType=${encodeURIComponent(searchType)}`}
              >
                <svg
                  style={{ width: "25px", height: "25px", margin: "5px" }}
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M38 38L29.3 29.3M34 18C34 26.8366 26.8366 34 18 34C9.16344 34 2 26.8366 2 18C2 9.16344 9.16344 2 18 2C26.8366 2 34 9.16344 34 18Z"
                    stroke="currentColor"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
              <span className={style.tooltiptext}>Search</span>
            </div>
          </div>
        </form>
        <div className={style.tooltip}>
          <a
            href={(() => {
              if (user) {
                return "/createproject";
              } else {
                const redirectPath = encodeURIComponent("/createproject");
                return `/signin?redirect=${redirectPath}`;
              }
            })()}
          >
            <svg
              width="30"
              height="30"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 12V28M12 20H28M6 2H34C36.2091 2 38 3.79086 38 6V34C38 36.2091 36.2091 38 34 38H6C3.79086 38 2 36.2091 2 34V6C2 3.79086 3.79086 2 6 2Z"
                stroke="currentColor"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
          <span className={style.tooltiptext}>New Project</span>
        </div>
        {/* If user is signed in, get rid of the sign in and register buttons */}
        {user && user.profileCompleted ? (
          <div className={style.profileSection}>
            <div className={style.tooltip}>
              <NotificationIcon
                notificationCount={
                  user.notifications ? user.notifications.length : 0
                }
              />
              <span className={style.tooltiptext}>Notifications</span>
            </div>
            <div className={style.tooltip}>
              <a href={`/chats`}>
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.3335 36.6666V6.66659C3.3335 5.74992 3.65989 4.9652 4.31266 4.31242C4.96544 3.65964 5.75016 3.33325 6.66683 3.33325H33.3335C34.2502 3.33325 35.0349 3.65964 35.6877 4.31242C36.3404 4.9652 36.6668 5.74992 36.6668 6.66659V26.6666C36.6668 27.5833 36.3404 28.368 35.6877 29.0208C35.0349 29.6735 34.2502 29.9999 33.3335 29.9999H10.0002L3.3335 36.6666ZM8.5835 26.6666H33.3335V6.66659H6.66683V28.5416L8.5835 26.6666Z"
                    fill="currentColor"
                  />
                </svg>
              </a>
              <span className={style.tooltiptext}>Messages</span>
            </div>
            <div className={style.dropdown}>
              <a href={`/users/${user.id}`} className={style.dropdownBtn}>
                {user.profilePhotoId ? (
                  <Image id={user.profilePhotoId} styleType={style.userImage} />
                ) : (
                  <img
                    src={defaultProfilePhoto}
                    className={style.userImage}
                    alt="Default Profile"
                  />
                )}
              </a>
              <div className={style.dropdownContent}>
                <a href={`/users/${user.id}`}> My Profile </a>
                <a href="#" onClick={HandleEdit}>
                  {" "}
                  Edit Profile{" "}
                </a>
                <a href="#" onClick={HandleSettings}>
                  {" "}
                  Settings{" "}
                </a>
                <a href="#" onClick={logOut}>
                  {" "}
                  Log Out{" "}
                </a>
              </div>
            </div>
          </div>
        ) : (
          <>
            <a className={style.NavLink} href="/register">
              Register
            </a>
            <a className={style.NavLink} href="/signin">
              Sign In
            </a>
          </>
        )}
      </div>
      <div className={style.NavItemsNarrow}>
        <div className={style.VisibleContent}>
          <a className={style.LogoLink} href="/">
            <img
              src={logoSmall}
              alt="logo link to home"
              style={{ width: "40px", height: "40px" }}
            />
          </a>
          <form className={style.searchForm} onSubmit={handleFormSubmit}>
            <div className={style.searchBar}>
              <input
                className={style.searchInput}
                name="query"
                placeholder="Search projects and peers"
                value={query}
                onChange={handleQueryChange}
              />
              <div className={style.tooltip}>
                <a
                  className={style.searchLink}
                  href={`/search?query=${encodeURIComponent(
                    query
                  )}&searchType=${encodeURIComponent(searchType)}`}
                >
                  <svg
                    style={{ width: "25px", height: "25px", margin: "5px" }}
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M38 38L29.3 29.3M34 18C34 26.8366 26.8366 34 18 34C9.16344 34 2 26.8366 2 18C2 9.16344 9.16344 2 18 2C26.8366 2 34 9.16344 34 18Z"
                      stroke="currentColor"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
                <span className={style.tooltiptext}>Search</span>
              </div>
            </div>
          </form>
          <a
            href="javascript:void(0);"
            className={style.menuButton}
            onMouseDown={expandMenu}
          >
            <svg
              width="30"
              height="30"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 24H42M6 12H42M6 36H42"
                stroke="currentColor"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
        </div>
        <div id="menuContent" className="Collapsed">
          <a
            href={(() => {
              if (user) {
                return "/createproject";
              } else {
                const redirectPath = encodeURIComponent("/createproject");
                return `/signin?redirect=${redirectPath}`;
              }
            })()}
          >
            Create Project
          </a>
          {user && user.profileCompleted ? (
            <>
              <a href={`/notifications`}>Notifications</a>
              <a href={`/chats`}>Messages</a>
              <a href={`/users/${user.id}`}> My Profile </a>
              <a href="#" onClick={HandleEdit}>
                {" "}
                Edit Profile{" "}
              </a>
              <a href="#" onClick={HandleSettings}>
                {" "}
                Settings{" "}
              </a>
              <a href="#" onClick={logOut}>
                {" "}
                Log Out{" "}
              </a>
            </>
          ) : (
            <>
              <a className={style.NavLink} href="/register">
                Register
              </a>
              <a className={style.NavLink} href="/signin">
                Sign In
              </a>
            </>
          )}
        </div>
      </div>
    </nav>
  );
}

export default TopNav;
