import React, { useState } from "react";
import styles from "../styles/modules/ToolTip.module.css";

const ToolTip = ({ message }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={styles.helpContainer}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={styles.questionMarkCircle}>?</div>
      {isHovered && <div className={styles.helpMessage}>{message}</div>}
    </div>
  );
};

export default ToolTip;
