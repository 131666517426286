// src/pages/CreateUserPage.js
import React, { useState, useEffect } from "react";
import { useFormContext } from "../context/FormContext";
import { Navigate } from "react-router-dom";
import api from "../config/AxiosConfig";
import { useNavigate } from "react-router-dom";
import style from "../styles/modules/CreationForms.module.css";
import CharacterCounterInput from "../components/CharacterCounterInput";
import ToolTip from "../components/ToolTip";
import TagSelector from "../components/TagSelector";

function CreateUserPage() {
  const { isFormSubmitted } = useFormContext();
  const [user, setUser] = useState(null);
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    pronouns: "",
    email: "",
    emailVerified: true,
    verificationToken: null,
    tokenExpiration: null,
    passwordHash: "", //unused here
    profileCompleted: false,
    school: "",
    major: "",
    projectsId: [],
    profilePhotoId: "",
    skills: [],
    description: "",
  });
  const navigate = useNavigate();
  const [formCompleted, setFormCompleted] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!formCompleted) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formCompleted]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await api.get("public/auth/getCurrentUser", {
          withCredentials: true,
        });
        if (response.status === 200) {
          setUser(response.data);
          setFormData((prevData) => ({
            ...prevData,
            id: response.data.id,
            email: response.data.email,

            passwordHash: response.data.passwordHash,
            school: "University of Utah",
          }));
        }
      } catch (error) {
        console.error("Error fetching user:", error);
        navigate("/signin");
      }
    };
    fetchUser();
  }, [navigate]);

  // Check if the 'registerForm' has been submitted
  if (!isFormSubmitted("registerForm")) {
    return <Navigate to="/register" />;
  }

  const handleInputChange = async (e) => {
    const { name, value, type, files } = e.target;
    console.log(name, value);
    // If it's the image field, handle the image upload separately
    if (name === "profilePhotoId" && files && files[0]) {
      setFormData((prevData) => ({
        ...prevData,
        profilePhotoId: files[0],
      }));
    } else {
      // Handle regular input changes
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    const isFormComplete =
      formData.name.trim() !== "" &&
      formData.major.trim() !== "" &&
      // formData.profilePhotoId !== "" && //don't check because photoId isnt assigned until submission
      formData.skills.length > 0 &&
      formData.description.trim() !== "";
    setFormCompleted(isFormComplete);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    var isError = false;
    //Data validation checks
    const validPronouns = [
      "He/Him",
      "She/Her",
      "They/Them",
      "He/They",
      "She/They",
      "Any Pronouns",
      "Other",
      "Unspecified",
    ];
    if (formData.name.length > 50) {
      alert("Name must be 50 characters or less.");
      isError = true;
    }
    if (!validPronouns.includes(formData.pronouns)) {
      console.log(formData.pronouns);
      alert("Please select valid pronouns.");
      isError = true;
    }
    if (formData.major.length > 50) {
      alert("Major must be 50 characters or less.");
      isError = true;
    }
    if (formData.description.length > 2000) {
      alert("Personal bio must be 2000 characters or less.");
      isError = true;
    }
    formData.skills.forEach((skill, i) => {
      if (skill.length > 30) {
        alert("Each skill must be 30 characters or less.");
        isError = true;
      }
    });
    if (formData.skills.length < 1 || formData.skills.length > 10) {
      alert("Enter at least 1 and a maximum of 10 skill tags.");
      isError = true;
    }
    if (
      formData.profilePhotoId &&
      formData.profilePhotoId.type !== "image/jpeg" &&
      formData.profilePhotoId.type !== "image/jpg"
    ) {
      alert("Please select a .jpg or .jpeg file for your profile picture.");
    }
    if (!formData.profilePhotoId) {
      // Ensure the user uploads a profile photo
      alert(
        "Please upload a profile picture. Don't worry, you can always change it later!"
      );
      isError = true;
    }

    if (isError) {
      isError = false;
      return;
    }

    // Create FormData for image upload
    const formDataImage = new FormData();
    formDataImage.append("file", formData.profilePhotoId);

    // Start the image upload
    api
      .post("/images/upload", formDataImage, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      })
      .then((imageUploadResponse) => {
        console.log("Successfully uploaded image data.");
        if (
          imageUploadResponse.status === 200 ||
          imageUploadResponse.status === 201
        ) {
          console.log("Image uploaded successfully:", imageUploadResponse.data);

          // Directly include the image ID in the form data for the profile update
          const updatedFormData = {
            ...formData,
            profilePhotoId: imageUploadResponse.data,
          };

          // Now, send the profile update request with the updated form data
          return api.put(`/users/${user.id}`, updatedFormData, {
            withCredentials: true,
          });
        } else {
          throw new Error("Image upload failed.");
        }
      })
      .then((response) => {
        // Handle successful profile update
        if (response.status === 200 || response.status === 201) {
          console.log("Successfully updated user profile");
          const params = new URLSearchParams(window.location.search);
          const redirectPath = params.get("redirect") || "/search";
          window.location.href = redirectPath;
        }
      })
      .catch((error) => {
        try {
          // Clear the JWT cookie by making a request to the backend logout endpoint
          api.post("/public/auth/logout", { withCredentials: true });
          setUser(null);
          console.log("Logged out");
        } catch (error) {
          console.error("Error during logout:", error);
        }
        // Handle errors from either image upload or profile update
        console.error("Error:", error);
        alert("Error creating profile, please try again.");
        navigate("/signin");
      });
  };
  return (
    <>
      <div class={style.container}>
        <div>
          <h1 class={style.title}>
            Create Your Profile
            <ToolTip message="Customize your profile to help your peers FindU! Don't stress - all fields can be changed anytime in the future!"></ToolTip>
          </h1>
          <form className={style.formBody} onSubmit={handleSubmit}>
            <div className={style.entryField}>
              <label className={style.fieldLabel} htmlFor="name">
                Name:
                <ToolTip message="Enter the name that you want your peers to know you as. We recommend using your 'real-life' preferred name, opposed to a username or screen name."></ToolTip>
              </label>
              <CharacterCounterInput
                type="input"
                className={style.wideTextEntry}
                name="name"
                id="name"
                required
                maxChars={50}
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Enter your preferred name"
              />
            </div>

            <div className={style.entryField}>
              <label className={style.fieldLabel} htmlFor="name">
                Pronouns:
                <ToolTip message="Enter your preferred pronouns. If your preferred pronouns are not listed, please specify them in your personal bio!"></ToolTip>
              </label>
              <select
                id="pronouns"
                name="pronouns"
                value={formData.pronouns}
                onChange={handleInputChange}
                required
              >
                <option value="" disabled>
                  -- Select your preferred pronouns --
                </option>
                <option value="He/Him">He/Him</option>
                <option value="She/Her">She/Her</option>
                <option value="They/Them">They/Them</option>
                <option value="He/They">He/They</option>
                <option value="She/They">She/They</option>
                <option value="Other">
                  Other - Please specify in personal bio below!
                </option>
                <option value="Unspecified">Prefer not to say</option>
              </select>
            </div>

            <div className={style.entryField}>
              <label className={style.fieldLabel} htmlFor="email">
                School email:{" "}
                <ToolTip message="Your university email. This will be kept private by default and only used for official FindU communications."></ToolTip>
              </label>
              <CharacterCounterInput
                type="input"
                className={style.wideTextEntry}
                name="email"
                id="email"
                required
                maxChars={50}
                value={formData.email}
                placeholder="Your school email"
                disabled
              />
            </div>

            <div className={style.entryField}>
              <label className={style.fieldLabel} htmlFor="school">
                Name of your school or university:{" "}
                <ToolTip message="Your school or university determines which FindU users you can connect with."></ToolTip>
              </label>
              <CharacterCounterInput
                type="input"
                className={style.wideTextEntry}
                name="school"
                id="school"
                required
                maxChars={100}
                value={formData.school}
                placeholder="Your school or university"
                disabled
              />
            </div>

            <div className={style.entryField}>
              <label className={style.fieldLabel} htmlFor="major">
                Your major:
                <ToolTip message="Enter your current area of study. If you are enrolled in a minor, certificate, or other academic plan, include it in the 'Skill Tags' section below."></ToolTip>
              </label>
              <CharacterCounterInput
                type="input"
                className={style.wideTextEntry}
                name="major"
                id="major"
                required
                maxChars={50}
                value={formData.major}
                onChange={handleInputChange}
                placeholder="Your major or area of interest"
              />
            </div>

            <div className={style.entryField}>
              <label className={style.fieldLabel} htmlFor="description">
                Personal bio:{" "}
                <ToolTip message="Tell your peers about yourself!"></ToolTip>
              </label>
              <CharacterCounterInput
                type="textarea"
                className={style.largeTextEntry}
                name="description"
                id="description"
                required
                maxChars={2000}
                value={formData.description}
                onChange={handleInputChange}
                placeholder="What should your peers know about you?"
              />
            </div>
            <div className={style.entryField}>
              <TagSelector
                formData={formData}
                setFormData={setFormData}
                formDataField={"skills"}
                toolTipMsg={
                  "Skill tags are the place to specify your areas of expertise and interests. These tags allow your peers to FindU easier!"
                }
              />
            </div>
            <div class={style.entryField}>
              <label class={style.fieldLabel} for="profilePhotoId">
                Profile picture
                <ToolTip message="Upload a profile picture to complete your profile! If no picture is selected, a default picture will be assigned to you."></ToolTip>
              </label>
              <input
                name="profilePhotoId"
                class={style.fileInput}
                type="file"
                accept=".jpg, .jpeg"
                onChange={handleInputChange}
              />
            </div>
            <div class={style.buttonContainer}>
              <button class={style.createButton}>Create profile</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default CreateUserPage;
