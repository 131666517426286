import React from "react";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'; // Or your preferred adapter
import dayjs from 'dayjs';

function MyDateTimePicker({ value, onChange, label = "choose event time" }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        label={label}
        value={value ? dayjs(value) : null}
        onChange={(newValue) => {
          if (newValue) {
            onChange(newValue.toISOString());
          } else {
            onChange("");
          }
        }}
        format="MM/dd/yyyy hh:mm a"
        viewRenderers={{
            hours: null,
            minutes: null,
            seconds: null,
        }}
      />
    </LocalizationProvider>
  );
}

export default MyDateTimePicker;
