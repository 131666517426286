import style from "../styles/modules/UserPage.module.css";
import Popup from "reactjs-popup";
import SelectOptions from "../components/SelectOptions";

function InvitePopup({
    HandleInvite,
    inviteMessage,
    setInviteMessage,
    currentUser,
    inviteProject,
    setInviteProject,
    userId,
    projects,
  }) {
    if (
      currentUser == null ||
      typeof currentUser.projectsId == null ||
      !currentUser.projectsId
    ) {
      return <></>;
    }
    var validProjects = new Array();
    if (projects) {
      for (let id in currentUser.projectsId) {
        if (projects.includes && !projects.includes(currentUser.projectsId[id])) {
          validProjects.push(currentUser.projectsId[id]);
        }
      }
    }
    if (validProjects.length < 1) {
      return <></>;
    }
    const handleMessageChange = (e) => {
      setInviteMessage(e.target.value);
    };
    const handleProjectChange = (e) => {
      setInviteProject(e.target.value);
    };
    const handleSubmit = () => {
      HandleInvite();
    };
  
    return (
      <>
        <Popup
          trigger={
            <button class={style.contactButton} type="Button">
              Invite to Project
            </button>
          }
          modal
        >
          {(close) => (
            <div className="modal">
              <div className={style.popup}>
                <div className="content">
                  <p>
                    Let this user know why they'd be a great fit for your project!
                  </p>
                  <div className={style.selectRow}>
                    Select Project:
                    <div className={style.selectSpace}>
                      <SelectOptions
                        name="projectChoice"
                        selected={inviteProject}
                        id="projectChoice"
                        onChange={handleProjectChange}
                        options={validProjects}
                        type="project"
                      />
                    </div>
                  </div>
                  <textarea
                    className={style.popupTextInput}
                    name="inviteMessage"
                    minLength="4"
                    maxLength="250"
                    value={inviteMessage}
                    onChange={handleMessageChange}
                  />
                </div>
                <div>
                  <button
                    className={style.sendInviteButton}
                    onClick={() => {
                      handleSubmit();
                      close();
                    }}
                  >
                    Send Invitation
                  </button>
                </div>
              </div>
            </div>
          )}
        </Popup>
      </>
    );
  }export default InvitePopup;