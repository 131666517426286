import { useState, useEffect } from "react";
import style from "../styles/modules/TagSelector.module.css";
import ToolTip from "./ToolTip";

const TagSelector = ({ formData, setFormData, formDataField, toolTipMsg }) => {
  const [skills, setSkills] = useState([]);
  const [newTag, setNewTag] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  if (!formData[formDataField]) {
    formData[formDataField] = [];
  }

  useEffect(() => {
    // Fetch all skills from the text file
    fetch("/all_skills.txt")
      .then((response) => response.text())
      .then((text) => {
        const skillList = text.split("\n").map((s) => s.trim());
        setSkills(skillList);
      })
      .catch((error) => console.error("Error loading skills:", error));
  }, []);

  const handleTagTextChange = (e) => {
    const value = e.target.value;
    setNewTag(value);

    if (value.length > 0) {
      const matches = skills
        .filter((skill) => skill.toLowerCase().includes(value.toLowerCase()))
        .slice(0, 5); // Limit to top 5 results
      //   console.log("setting matches: ", matches);
      setFilteredSuggestions(matches);
    } else {
      setFilteredSuggestions([]);
    }
  };

  const handleAddTag = (tag) => {
    if (
      tag.trim() &&
      !formData[formDataField].includes(tag) &&
      formData[formDataField].length < 10
    ) {
      setFormData((prevData) => ({
        ...prevData,
        [formDataField]: [...prevData[formDataField], tag.trim()],
      }));
      setNewTag("");
      setFilteredSuggestions([]);
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setFormData((prevData) => ({
      ...prevData,
      [formDataField]: prevData[formDataField].filter(
        (tag) => tag !== tagToRemove
      ),
    }));
  };

  return (
    <>
      <label class={style.fieldLabel} for="tags">
        {formDataField === "tags" || formDataField === "projectTags"
          ? "Project Tags"
          : "Skills"}
        :{toolTipMsg ? <ToolTip message={toolTipMsg} /> : <></>}
      </label>
      <div>
        <input
          name="tags"
          id="tags"
          class={style.smallTextInput}
          type="text"
          value={newTag}
          onChange={handleTagTextChange}
          maxLength={30}
          placeholder="Enter a tag"
        />
        <button
          type="button"
          class={style.addButton}
          onClick={() => handleAddTag(newTag)}
          disabled={formData[formDataField].length >= 10 || !newTag.trim()}
        >
          Add{" "}
          {formDataField === "tags" || formDataField === "projectTags"
            ? "Tag"
            : "Skill"}
        </button>
      </div>
      {/* Suggestion Dropdown */}
      {filteredSuggestions.length > 0 && (
        <ul class={style.suggestionList}>
          {filteredSuggestions.map((suggestion, index) => (
            <li
              key={index}
              onClick={() => {
                handleAddTag(suggestion);
              }}
              class={style.suggestionItem}
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}
      <div>
        {formData[formDataField].length > 0 && (
          <div className={style.tags}>
            {formData[formDataField].map((tag, index) => (
              <div key={index} className={style.tag}>
                {tag}
                <button
                  class={style.removeButton}
                  type="button"
                  onClick={() => handleRemoveTag(tag)}
                />
              </div>
            ))}
          </div>
        )}
        {formDataField === "tags" || formDataField === "skills" ? (
          <small>Add up to 10 {formDataField}. At least 1 is required.</small>
        ) : (
          <small>Add up to 10 tags.</small>
        )}
      </div>
    </>
  );
};

export default TagSelector;
