import { NavLink, useLocation, useNavigate } from "react-router-dom";
import style from "../styles/modules/Footer.module.css";

function Footer() {
    return (<footer className={style.footer}>
        <div className={style.footerContent}>
            <p>This website was created by University of Utah students Amelia Johnson, Landen Hughes, Renee Tong, and Sydney Andrus as their senior capstone project.</p>
            <p>Techincal support for this site can be reached at <a href="mailto:support@findu.online">support@findu.online</a>.</p>
        </div>
    </footer>)
}

export default Footer;