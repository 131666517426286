import style from "../styles/modules/SearchPage.module.css";

function Tags({ tags }) {
    if (typeof(tags) == 'undefined' || tags == null) {
        return (<></>);
    }
    return ( <div class={style.tags}>
        { tags.map((tag, i) => (<div class={style.tag} key={ i }> { tag }</div>)) }
    </div>
    );
}

export default Tags;