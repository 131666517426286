import React, { useState, useEffect } from "react";
import api from "../config/AxiosConfig";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Image from "../components/Image";
import style from "../styles/modules/UserPage.module.css";
import editStyle from "../styles/modules/EditUserPage.module.css";
import { useUser } from "../context/UserContext";
import CharacterCounterInput from "../components/CharacterCounterInput";
import { toast } from "react-toastify";
import TagSelector from "../components/TagSelector";
import SelectOptions from "../components/SelectOptions";

function EditImage({
  imageId,
  handleRemoveImage,
  handleInputChange,
  imageChanging,
  setImageChanging,
}) {
  const handleImageChange = (e) => {
    setImageChanging(true);
  };
  console.log("image id: ", imageId);
  if (imageId == null || imageChanging) {
    return (
      <div className={editStyle.imageSettings}>
        <div class={style.entryField}>
          <label class={style.fieldLabel} for="image">
            Profile Picture
          </label>
          <input
            name="image"
            class={style.fileInput}
            type="file"
            accept=".jpg, .jpeg"
            onChange={handleInputChange}
          />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className={style.imageContainer}>
          <Image id={imageId} styleType={editStyle.profilePicture} />
        </div>
        <div className={editStyle.imageButtons}>
          <button type="button" onClick={handleRemoveImage}>
            Remove Image
          </button>
          <button type="button" onClick={handleImageChange}>
            Change Image
          </button>
        </div>
      </>
    );
  }
}

function DeleteUserSection() {
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const { user, setUser } = useUser();

  const handleCheck = (e) => {
    setChecked(e.target.checked);
  };

  const handleDelete = () => {
    console.log("Deleting... ", user.id);
    api
      .delete(`/users/${user.id}`, { withCredentials: true })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          console.log("got OK from deletion");
          api
            .post("/public/auth/logout", { withCredentials: true })
            .then((response) => {
              if (response.status === 200 || response.status === 201) {
                navigate(`/search?query=&searchType=users`);
                window.location.reload();
              }
            });
        }
      })
      .catch((error) => {
        console.error("There was an error deleting the user!", error);
      });
  };

  return (
    <>
      <div className={editStyle.deleteSection}>
        <div className={editStyle.deleteCheck}>
          <input type="checkbox" onChange={handleCheck} name="deleteCheck" />
          <label for="deleteCheck">
            I am certain I want to delete my account. I understand this action
            cannot be undone.
          </label>
        </div>
        <button
          type="button"
          className={editStyle.deleteButton}
          disabled={!checked}
          onClick={handleDelete}
        >
          {" "}
          Delete Profile{" "}
        </button>
      </div>
    </>
  );
}

function EditUserPage() {
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const userId = useParams().userId;
  const [imageChanging, setImageChanging] = useState(false);
  const { user, setUser } = useUser();
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    pronouns: "",
    email: "",
    emailVerified: true,
    verificationToken: null,
    tokenExpiration: null,
    profileCompleted: false,
    school: "",
    major: "",
    projectsId: [],
    profilePhotoId: "",
    skills: [],
    description: "",
  });

  useEffect(() => {
    api
      .get(`/users/${userId}`)
      .then((response) => {
        if (response.data.id && user && response.data.id === user.id) {
          setFormData(response.data);
          console.log("Updated form data: ", formData);
        } else {
          navigate(`/users/${userId}`);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data:", error);
      });
  }, [userId, user, location]);

  const HandleCancel = (e) => {
    navigate(`/users/${userId}`);
    window.location.reload();
  };

  const handleSubmit = async (e) => {
    if (formData.skills.length < 1 || formData.skills.length > 10) {
      toast.error("Include at least 1 and at most 10 skill tags.");
      return;
    }
    e.preventDefault(); //prevent page reload
    try {
      const response = await api.put(`/users/${userId}`, formData);
      if (response.status === 200 || response.status === 201) {
        console.log("Successfully updated user profile");
        setUser(response.data);
      }
      navigate(`/users/${userId}`);
      window.location.reload();
    } catch (error) {
      console.error("Error updating project:", error);
    }
  };

  const handleRemoveImage = () => {
    setFormData((prevData) => ({
      ...prevData,
      profilePhotoId: null,
    }));
  };

  const handleInputChange = async (e) => {
    const { name, value, type, files } = e.target;

    // If it's the image field, handle the image upload separately
    if (name === "image" && files && files[0]) {
      try {
        setIsUploading(true);
        // Create a FormData object to send the file
        const formDataImage = new FormData();
        formDataImage.append("file", files[0]);

        // Send image to backend
        const response = await api.post("/images/upload", formDataImage, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        // If image upload is successful, update formData with the imageId
        if (response.status === 200 || response.status === 201) {
          setFormData((prevData) => ({
            ...prevData,
            profilePhotoId: response.data,
          }));
          setImageChanging(false);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }finally {
        setIsUploading(false);
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const pronounsDisplay = new Map([
    ["disabled", "---Select Prefered Pronouns---"],
    ["He/Him", "He/Him"],
    ["She/Her", "She/Her"],
    ["They/Them", "They/Them"],
    ["He/They", "He/They"],
    ["She/They", "She/They"],
    ["Any Pronouns", "Any Pronouns"],
    ["Other", "Other - Please specify in personal bio below!"],
    ["Unspecified", "Prefer not to say"],
  ]);

  const validPronouns = [
    "disabled",
    "He/Him",
    "She/Her",
    "They/Them",
    "He/They",
    "She/They",
    "Any Pronouns",
    "Other",
    "Unspecified",
  ]

  return (
    <>
      <h1>Edit Profile</h1>
      <form onSubmit={handleSubmit} className={editStyle.formBody}>
        <div class={editStyle.userHeader}>
          <EditImage
            imageId={formData.profilePhotoId}
            handleInputChange={handleInputChange}
            handleRemoveImage={handleRemoveImage}
            imageChanging={imageChanging}
            setImageChanging={setImageChanging}
          />
          <div class={editStyle.stacked}>
            <label class={editStyle.fieldLabel} htmlFor="name">
              Full name:
            </label>
            <CharacterCounterInput
              type="input"
              className={editStyle.nameEntry}
              name="name"
              id="name"
              required
              maxChars={50}
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Enter your preferred name"
            />
            <SelectOptions
              name="pronouns"
              id="pronouns"
              value={formData.pronouns}
              onChange={handleInputChange}
              options={validPronouns}
              displayedOptions={pronounsDisplay}
              includingDisplayed={true}
            />
          </div>
        </div>
        <div>
          <label class={editStyle.fieldLabel} htmlFor="description">
            Bio:
          </label>
          <CharacterCounterInput
            type="textarea"
            className={editStyle.descriptionEntry}
            name="description"
            id="description"
            required
            maxChars={2000}
            value={formData.description}
            onChange={handleInputChange}
            placeholder="What should your peers know about you?"
          />
        </div>
        <div className={editStyle.tagSelection}>
          <TagSelector
            formData={formData}
            setFormData={setFormData}
            formDataField={"skills"}
          />
        </div>
        <div class={editStyle.formButtons}>
          <button
            className={editStyle.cancelButton}
            onClick={HandleCancel}
            type="button"
          >
            Cancel Changes
          </button>
          <button
            className={editStyle.submitButton}
            onClick={handleSubmit}
            type="button"
            disabled={isUploading}
          >
            {isUploading ? "Uploading Image..." : "Update Profile"}
          </button>
        </div>
      </form>
      <div class={editStyle.deleteUserSection}>
        <DeleteUserSection />
      </div>
    </>
  );
}

export default EditUserPage;
