/**
 * this context acts as a wrapper around the entire app. it keeps track of the submission status of various forms
 * across the site to restrict certain pages. probably will be a better way to do this down the line once we implement authentication.
**/

import React, { createContext, useState, useContext, useEffect } from 'react';

const FormContext = createContext();

export const useFormContext = () => {
  return useContext(FormContext);
};

export const FormProvider = ({ children }) => {
  const [formStatuses, setFormStatuses] = useState({});

  // Load form submission statuses from sessionStorage
  useEffect(() => {
    const storedStatuses = JSON.parse(sessionStorage.getItem('formStatuses')) || {};
    setFormStatuses(storedStatuses);
  }, []);

  const markFormAsSubmitted = (formName) => {
    const updatedStatuses = { ...formStatuses, [formName]: true };
    setFormStatuses(updatedStatuses);
    sessionStorage.setItem('formStatuses', JSON.stringify(updatedStatuses));
  };

  const isFormSubmitted = (formName) => {
    return formStatuses[formName] || false;
  };

  return (
    <FormContext.Provider value={{ markFormAsSubmitted, isFormSubmitted }}>
      {children}
    </FormContext.Provider>
  );
};

