import React from "react";
import Badge from "./Badge";

const NotificationIcon = ({ notificationCount }) => {
  return (
    <a
      href="/notifications"
      style={{ position: "relative", display: "inline-block" }}
    >
      <svg width="30" height="30" viewBox="0 0 40 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.46 40C23.1084 40.6062 22.6037 41.1093 21.9965 41.4591C21.3892 41.8088 20.7008 41.9929 20 41.9929C19.2992 41.9929 18.6108 41.8088 18.0035 41.4591C17.3963 41.1093 16.8916 40.6062 16.54 40M32 14C32 10.8174 30.7357 7.76516 28.4853 5.51472C26.2348 3.26428 23.1826 2 20 2C16.8174 2 13.7652 3.26428 11.5147 5.51472C9.26428 7.76516 8 10.8174 8 14C8 28 2 32 2 32H38C38 32 32 28 32 14Z" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <Badge count={notificationCount} position="top-right" />
    </a>
  );
};

export default NotificationIcon;
