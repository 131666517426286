import React, { useState, useEffect } from "react";
import api from "../config/AxiosConfig.js";
import { useLocation, useNavigate } from "react-router-dom";
import style from "../styles/modules/NotificationPage.module.css";
import Image from "../components/Image";
import Timestamp from "../components/Timestamp";
import X from "../assets/XBlack.png";

function MemberInfo({ id, projectName, senderTitle }) {
  const [memberInfo, setMemberInfo] = useState([]);
  useEffect(() => {
    api
      .get(`/users/${id}`)
      .then((response) => {
        setMemberInfo(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the user data!", error);
      });
  }, [id]);
  const url = "/users/" + id;

  var bottomText = senderTitle + " at " + projectName;

  if (!projectName || projectName == "" || !senderTitle) {
    bottomText = memberInfo.major;
    console.log(memberInfo);
  }

  return (
    <>
      <div class={style.memberInfo}>
        <Image
          id={memberInfo.profilePhotoId}
          styleType={style.profilePicture}
        />
        <div class={style.memberDetails}>
          <a href={url}>{memberInfo.name} </a>
          <h4>
            {bottomText}
          </h4>
        </div>
      </div>
    </>
  );
}

function ProjectNotification({ notification, handleDismiss}) {
  const [projectInfo, setProjectInfo] = useState([]);
  useEffect(() => {
    api
      .get(`/projects/${notification.projectId}`)
      .then((response) => {
        setProjectInfo(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the project data!", error);
      });
  }, []);

  var url = "/projects/" + notification.projectId;

  const handleButton = (e) => {
    handleDismiss(notification);
  };

  var senderTitle = "Project Member";

  try {
    senderTitle = projectInfo.userTitles[notification.fromId];
  } catch (error) {
    console.error("Error setting sender title");
  }

  var linkText = "Check out " + projectInfo.title;

  return (
    <>
      <div class={style.notification}>
        <div class={style.top}>
          <MemberInfo
            id={notification.fromId}
            projectName={projectInfo.title}
            senderTitle={senderTitle}
          />
          <p>
            <Timestamp timeGiven={notification.timeSent} displayType="notif" />
          </p>
        </div>
        <p>{notification.message}</p>
        <div class={style.bottom}>
          <a href={url}>{linkText}</a>
          <button type="button" className={style.button} onClick={handleButton}>
            <svg width="25" height="25" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M36 12L12 36M12 12L36 36" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
      </div>
    </>
  );

}

function Notification({ notification, handleDismiss, currentUser }) {
  if (notification.projectId != "") {
    return (
      <ProjectNotification notification={notification} handleDismiss={handleDismiss} />
    )
  }
  var chatId = "";

  for (let c in currentUser.chats) {
    if (currentUser.chats[c] == notification.fromId) {
        chatId = c;
    }
  }
  var url = "/chats/" + chatId;
  console.log(url);
  var linkText = "Check out this chat!";

  const handleButton = (e) => {
    handleDismiss(notification);
  };

  return (
    <>
      <div class={style.notification}>
        <div class={style.top}>
          <MemberInfo
            id={notification.fromId}
          />
          <p>
            <Timestamp timeGiven={notification.timeSent} displayType="notif" />
          </p>
        </div>
        <p>{notification.message}</p>
        <div class={style.bottom}>
          <a href={url}>{linkText}</a>
          <button type="button" className={style.button} onClick={handleButton}>
            <img
              src={X}
              width="25" height="25"
            />
          </button>
        </div>
      </div>
    </>
  );
}

function NotificationPage() {
  const [currentUser, setCurrentUser] = useState(null);
  const [message, setMessage] = useState([]);
  useEffect(() => {
    api
      .get("public/auth/getCurrentUser", { withCredentials: true })
      .then((response) => {
        console.log("current User");
        console.log(response.data);
        console.log(response.status);
        if (response.status === 200 || response.status === 201) {
          setCurrentUser(response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  console.log(currentUser);

  if (
    currentUser == null ||
    currentUser.notifications == null ||
    currentUser.notifications.length == 0
  ) {
    return (
      <p>
        <b>No notifications</b>
      </p>
    );
  }

  const HandleDismiss = async (notif) => {
    try {
      console.log(notif);
      const dismissResponse = await api.post(
        `users/dismiss/${currentUser.id}`,
        notif
      );
      if (dismissResponse.status === 200 || dismissResponse.status === 201) {
        console.log("Successfully dismissed notification");
        window.location.reload();
      }
    } catch (error) {
      console.error("Error dismissing notification", error);
    }
  };


  return (
    <>
      <h1> Notifications </h1>
      <div className={style.notifs}>
        {currentUser.notifications.toReversed().map((notif, i) => (
          <Notification
            currentUser={currentUser}
            notification={notif}
            handleDismiss={HandleDismiss}
            key={i}
          />
        ))}
      </div>
    </>
  );
}

export default NotificationPage;
