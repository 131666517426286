import React from "react";

const Badge = ({ count, position = "top-right" }) => {
  if (count <= 0) return null;
  if (count > 99) count = "99+";

  const badgeStyles = {
    position: "absolute",
    top: position === "top-right" ? "0" : "auto",
    right: position === "top-right" ? "0" : "auto",
    bottom: position === "bottom-left" ? "0" : "auto",
    left: position === "bottom-left" ? "0" : "auto",
    background: "red",
    color: "white",
    borderRadius: "50%",
    width: "15px",
    height: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "10px",
    fontWeight: "bold",
    boxShadow: "0 0 4px rgba(0,0,0,0.5)",
  };

  return <div style={badgeStyles}>{count}</div>;
};

export default Badge;
