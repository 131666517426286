import React from "react";
import style from "../styles/modules/ProjectPage.module.css";
import Image from "../components/Image";

function ImageGallery({ imageIds }) {
    if (!imageIds || imageIds.length === 0) {
      return <></>;
    }
  
    return (
      <div className={style.galleryContainer}>
        <div className={style.galleryGrid}>
          {imageIds.map((imageId) => (
            <div key={imageId} className={style.galleryItem}>
              <Image id={imageId} styleType={style.galleryImage} />
            </div>
          ))}
        </div>
      </div>
    );
  }export default ImageGallery;