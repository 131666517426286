import React, { useEffect, useState } from "react";
import style from "../styles/modules/SearchPage.module.css";

function DescriptionText( { text, id } ) {
    const [extraInfo, setExtraInfo] = useState(false);
    const limitCharLength = 300;

    if (!text)
        return (<></>);

    return (
        <p>
            {(text.length < limitCharLength) ?
                (<>
                    {text}
                </>)
            : extraInfo ?
                (<>
                    {text + " "}
                    <a href={"#"+id} onClick={() => setExtraInfo(false)}>Show Less</a>
                </>)
            :   (<>
                    {(text.substring(0, limitCharLength) + "... ")}
                    <a href={"#"+id} onClick={() => setExtraInfo(true)}>Show More</a>
                </>)
            }
        </p>
    );
}

export default DescriptionText;