import UserPreview from "./UserPreview";

function UsersSearchResults( { responses, images }) {
    // if (!responses || responses.length === 0) {
    //     return <p>No users found for your search.</p>;
    // }
    return( <>
        {responses.map((user, i) => (
            <UserPreview
                key={i}
                name={user.name}
                major={user.major}
                school={user.school}
                id={user.id}
                image={user.profilePhotoId}
                // image={images[user.profilePhotoId]}
                description={user.description}
                tags={user.skills}
            />))}
    </> );
}
export default UsersSearchResults;