import style from "../styles/modules/LandingPage.module.css";
import Image from "../components/Image";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from '../config/AxiosConfig.js';
import ProjectCard from "../components/ProjectCard.js";

function Projects( ) {
    const [projects, setProjects] = useState([]);
    useEffect(() => {
        api
          .get(`/projects`)
          .then((response) => {
            setProjects(response.data);
          })
          .catch((error) => {
            console.error("There was an error fetching the project data!", error);
          });
      }, []);
    const firstFive = projects.slice(0,5);
    return (<>
        {firstFive.map((project, i) => (
          <ProjectCard id={project.id} showJoined={false} />
        ))}
    </>)
}

function UserCard( {name, description, id, imageId, major} ) {
    const url = "/users/" + id;
    if(description.length > 130)
            description = description.substring(0,130)+"...";

    return (<>
        <div class={style.previewCard}>
            <div class={style.topInfo}>
                <Image id={imageId} styleType={style.userImage} />
                <h3><a href={url}> {name} </a></h3>
            </div>
            <div class={style.previewText}>
               <div>
                   <svg style={{ marginRight: 5 }} width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M42 20C42 34 24 46 24 46C24 46 6 34 6 20C6 15.2261 7.89642 10.6477 11.2721 7.27208C14.6477 3.89642 19.2261 2 24 2C28.7739 2 33.3523 3.89642 36.7279 7.27208C40.1036 10.6477 42 15.2261 42 20Z" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                     <path d="M24 26C27.3137 26 30 23.3137 30 20C30 16.6863 27.3137 14 24 14C20.6863 14 18 16.6863 18 20C18 23.3137 20.6863 26 24 26Z" stroke="currentColor" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                   </svg>
                   {major}
               </div>
               <div class={style.description}>
                <p>{description}</p>
              </div>
            </div>

        </div>
    </>)
}

function Users( ) {
    const [users, setUsers] = useState([]);
    useEffect(() => {
        api
          .get(`/users`)
          .then((response) => {
            setUsers(response.data);
          })
          .catch((error) => {
            console.error("There was an error fetching the project data!", error);
          });
      }, []);
    const firstFive = users.slice(0,5);
    return (<>
        {firstFive.map((user, i) => (
            <UserCard
                key={i}
                name={user.name}
                description={user.description}
                id={user.id}
                imageId={user.profilePhotoId}
                major={user.major}
            />
        ))}
    </>)
}

function LandingPage() {
  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  const handleQueryChange = (e) => {
    console.log("landing");
    console.log(e);
    setQuery(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    navigate(`/search?query=${encodeURIComponent(query)}&searchType=projects`); // Add searchType=projects
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default form submission
      navigate(
        `/search?query=${encodeURIComponent(query)}&searchType=projects`
      ); // Add searchType=projects
    }
  };

  const handleUserSearch = (e) => {
      console.log("searching users");
      //TODO: fix this navigation
      navigate(`/search?query=${encodeURIComponent(query)}&searchType=users`); // Add searchType=projects
  }

  const handleProjectSearch = (e) => {
      console.log("searching projects");
      //TODO: fix this navigation
      navigate(`/search?query=${encodeURIComponent(query)}&searchType=projects`); // Add searchType=projects
  }

  return (
    <div>
      <div class={style.Section1}>
        <h1>
          Find & collaborate <br></br>
          on the next big thing
        </h1>
      </div>
      <div class={style.Section2}>
        <div class={style.bannerContent}>
          <h2>
            FIND YOUR DREAM TEAM
          </h2>
          <h3>FindU shows you what projects are happening on campus and gives you the opportunity to get involved.</h3>
          <div class={style.previews}>
              <Projects/>
          </div>
          <button onClick={handleProjectSearch}>Explore all projects</button>
          <h2>
            work with talented people
          </h2>
          <h3>FindU allows you to advertise your personal projects and connect with students who have the skills you need.</h3>
          <div class={style.previews}>
              <Users/>
          </div>
          <button onClick={handleUserSearch}>Explore all users</button>
          <div class={style.bottomText}>
              {/* <h2>If you're already a user, please <a href="/signin">sign in</a>. If not, please <a href="/register">register</a> a new account to get started.</h2> */}
          </div>
          </div>
      </div>
    </div>
  );
}
export default LandingPage;
