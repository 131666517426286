import React from "react";

const CharacterCounterInput = ({
  maxChars,
  type = "input",
  className = "",
  name,
  id,
  required = false,
  value,
  onChange,
  placeholder = "",
  disabled,
}) => {
  return (
    <>
      {type === "textarea" ? (
        <textarea
          className={className}
          name={name}
          id={id}
          required={required}
          maxLength={maxChars}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
        />
      ) : (
        <input
          type="text"
          className={className}
          name={name}
          id={id}
          required={required}
          maxLength={maxChars}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
        />
      )}
      <div>
        {value.length}/{maxChars}
      </div>
    </>
  );
};

export default CharacterCounterInput;
