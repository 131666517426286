import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import style from "../styles/modules/CreationForms.module.css";
import CharacterCounterInput from "../components/CharacterCounterInput";
import api from "../config/AxiosConfig";
import { toast } from "react-toastify";
import MyDateTimePicker from "../components/DateTimePicker"; 
import ToolTip from "../components/ToolTip";
import { useParams } from "react-router-dom";


function AddEventPage() {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    startDateTime: null,
    endDateTime: null,
    location: "",
  });
  const { projectId } = useParams();


  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting:", formData);
    try {
    const response = await api.post(`/${projectId}/addEvent`, formData, {
        withCredentials: true,
        });
      if (response.status === 200 || response.status === 201) {
        toast.success("Event created successfully!");
        navigate(`/projects/${projectId}`);
      }
    } catch (err) {
      console.error("Event creation error:", err);
      toast.error("Failed to create event.");
    }
  };

  return (
    <div class={style.container}>
        <div>
            <h1 class={style.title}>
                Create A New Event
                <ToolTip
                message={
                    "Fill in the fields below to create your new project! Don't worry, all information and project settings can be changed later."
                }
                />
            </h1>
            <form 
                class={style.formBody}
                onSubmit={handleSubmit}>
            <div class={style.entryField}>
                <label className={style.fieldLabel} htmlFor="title">
                Event Name:
                <ToolTip
                  message={"Give your new project a name. How exciting!"}
                />
                </label>
                <CharacterCounterInput
                type="input"
                className={style.wideTextEntry}
                name="title"
                id="title"
                required
                maxChars={50}
                value={formData.title}
                onChange={handleInputChange}
              />
            </div>
            <div class={style.entryField}>
                <label className={style.fieldLabel} htmlFor="description">
                Event Description:
                <ToolTip
                  message={
                    "What should others know about your project? What do you want to do and how do you want to do it?"
                  }
                />
                </label>
                <CharacterCounterInput
                type="textarea"
                className={style.largeTextEntry}
                name="description"
                id="description"
                required
                maxChars={2000}
                value={formData.description}
                onChange={handleInputChange}
              />
            </div>
            <div class={style.entryField}>
                <label className={style.fieldLabel}>
                Start Time:
                <ToolTip
                  message={"Give your new project a name. How exciting!"}
                />
                </label>
                <MyDateTimePicker
                value={formData.startDateTime}
                onChange={(val) =>
                    setFormData((prev) => ({ ...prev, startDateTime: val }))
                }
                />
            </div>

            <div class={style.entryField}>
                <label className={style.fieldLabel}>
                End Time:
                <ToolTip
                  message={"Give your new project a name. How exciting!"}
                />
                </label>
                <MyDateTimePicker
                value={formData.endDateTime}
                onChange={(val) =>
                    setFormData((prev) => ({ ...prev, endDateTime: val }))
                }
                />
            </div>

            <div class={style.entryField}>
                <label className={style.fieldLabel} htmlFor="location">
                Location:
                <ToolTip
                  message={"Give your new project a name. How exciting!"}
                />
                </label>
                <CharacterCounterInput
                type="input"
                className={style.wideTextEntry}
                name="location"
                id="location"
                required
                maxChars={50}
                value={formData.location}
                onChange={handleInputChange}
              />
            </div>
            <div class={style.buttonContainer}>
              <button class={style.createButton}>Create Event</button>
            </div>
            </form>
        </div>
    </div>
  );
}

export default AddEventPage;