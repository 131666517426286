import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../config/AxiosConfig";
import { toast } from "react-toastify";
import style from "../styles/modules/AuthPages.module.css";
import ShowPasswordButton from "../components/ShowPasswordButton";
import logo from "../assets/IconWhite.png";

function ResetPasswordPage() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("user");
  const resetLinkToken = queryParams.get("token");

  useEffect(() => {
    // Check if token exists in URL
    if (!resetLinkToken || !userId) {
      navigate("/signin");
    }
  }, [resetLinkToken, userId]);

  const verifyToken = async () => {
    try {
      const response = await api.post("public/auth/verify-recovery-token", {
        token: resetLinkToken,
        userId: userId,
      });
      if (response.status !== 200) {
        toast.error(response.data);
      }
    } catch (error) {
      toast.error(`Error verifying your token: ${error}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }

    try {
      const response = await api.put("public/auth/reset-password", {
        token: resetLinkToken,
        userId: userId,
        newPassword: newPassword,
      });

      if (response.status === 200) {
        toast.success("Password successfully reset.");
        navigate("/signin");
      }
    } catch (error) {
      toast.error(`Error resetting your password: ${error}`);
    }
  };

  return (
    <div class={style.container}>
      <img class={style.logoImage} src={logo} alt="FindU Logo"></img>
      <form class={style.entryArea} onSubmit={handleSubmit}>
        <h1 class={style.formTitle}>Reset Your Password</h1>
        <table class={style.inputTable}>
          <tbody class={style.inputTableBody}>
            <tr class={style.columnTr}>
              <td>
                <label class={style.fieldLabel} for="password">
                  New Password:
                </label>
              </td>
              <td class={style.rowTd}>
                <input
                  class={style.formInput}
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Enter your new password"
                  required
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <ShowPasswordButton id="password" />
              </td>
            </tr>
            <tr class={style.columnTr}>
              <td>
                <label class={style.fieldLabel} for="confirmPassword">
                  Confirm New Password:
                </label>
              </td>
              <td class={style.rowTd}>
                <input
                  class={style.formInput}
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm your new password"
                  required
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <ShowPasswordButton id="confirmPassword" />
              </td>
            </tr>
          </tbody>
          <tfoot class={style.footer}>
            <td>
              <button class={style.submitButton} type="submit">
                Reset Password
              </button>
            </td>
          </tfoot>
        </table>
      </form>
    </div>
  );
}

export default ResetPasswordPage;
