import React, { useState, useEffect } from "react";
import api from "../config/AxiosConfig";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Image from "../components/Image";
import style from "../styles/modules/ProjectPage.module.css";
import editStyle from "../styles/modules/EditProjectPage.module.css";
import CharacterCounterInput from "../components/CharacterCounterInput";
import { useUser } from "../context/UserContext";
import TagSelector from "../components/TagSelector";
import { toast } from "react-toastify";
import SelectOptions from "../components/SelectOptions";
import ImageGalleryUpload from "../components/ImageGalleryUpload";

function EditImage({
  imageId,
  handleRemoveImage,
  handleInputChange,
  imageChanging,
  setImageChanging,
}) {
  const handleImageChange = (e) => {
    setImageChanging(true);
  };
  if (imageId == null || imageChanging) {
    return (
      <>
        <div class={style.entryField}>
          <label class={style.fieldLabel} for="image">
            Project Image
          </label>
          <input
            name="image"
            class={style.fileInput}
            type="file"
            accept=".jpg, .jpeg"
            onChange={handleInputChange}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <Image id={imageId} styleType={style.projectPhoto} />
        <div className={editStyle.imageButtonWrapper}>
          <button type="button" onClick={handleRemoveImage} className={style.changeImageButton}>
            Remove Image
          </button>
          <button type="button" onClick={handleImageChange} className={style.changeImageButton}>
            Change Image
          </button>
        </div>
      </>
    );
  }
}

function MemberCard({
  id,
  role,
  title,
  handleMemberRoleChange,
  handleMemberTitleChange,
  handleMemberRemove,
}) {
  //TODO: allow deleting members from project edit page
  const [memberInfo, setMemberInfo] = useState([]);
  useEffect(() => {
    api
      .get(`/users/${id}`)
      .then((response) => {
        setMemberInfo(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the user data!", error);
      });
  }, [id]);
  const url = "/users/" + id;
  //    console.log(title);

  return (
    <>
      <td>
        <div class={editStyle.memberCardInfo}>
          <Image
            id={memberInfo.profilePhotoId}
            styleType={style.profilePicture}
          />
          <div class={style.memberDetails}>
            <a href={url}>{memberInfo.name} </a>
            <p>{memberInfo.major}</p>
          </div>
        </div>
      </td>
      <td>
        <div>
          {role == "Owner" ? (
            <p>Owner</p>
          ) : (
            <div className={editStyle.dropdown}>
              <SelectOptions
                name="roleChoice"
                id={id}
                value={role}
                onChange={handleMemberRoleChange}
                options={["Admin", "Member"]}
              />
            </div>
          )}
        </div>
      </td>
      <td>
        <div class={editStyle.editTitle}>
          <input
            id={id}
            type="text"
            onChange={handleMemberTitleChange}
            maxLength="40"
            name="titleInput"
            value={title}
          />
        </div>
      </td>
      <td>
        <button
          className={editStyle.removeMemberButton}
          id={id}
          type="button"
          onClick={handleMemberRemove}
          disabled={role == "Owner"}
        >
          Remove From Project
        </button>
      </td>
    </>
  );
}

function Members({
  usersId,
  userRoles,
  userTitles,
  handleMemberRoleChange,
  handleMemberTitleChange,
  handleMemberRemove,
}) {
  if (!usersId || typeof usersId == "undefined" || usersId.length === 0) {
    return (
      <>
        <p>This project does not currently have any members.</p>
      </>
    );
  }

  //    console.log(userRoles);

  return (
    <div class={editStyle.memberCards}>
      <table>
        <tr>
          <th>Member</th>
          <th>Role</th>
          <th>Title</th>
        </tr>
        {usersId.map((id, i) => (
          <tr>
            <MemberCard
              id={id}
              role={userRoles[id]}
              title={userTitles[id]}
              handleMemberRoleChange={handleMemberRoleChange}
              handleMemberTitleChange={handleMemberTitleChange}
              handleMemberRemove={handleMemberRemove}
              key={i}
            />
          </tr>
      ))}
      </table>
    </div>
  );
}

function DeleteProjectSection({ projectID }) {
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();

  const handleCheck = (e) => {
    setChecked(e.target.checked);
  };

  const handleDelete = () => {
    api
      .delete(`/projects/${projectID}`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          navigate(`/search?query=&searchType=projects`);
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error("There was an error deleting the project!", error);
      });
  };

  return (
    <>
      <div className={editStyle.deleteSection}>
        <div className={editStyle.deleteCheck}>
          <input type="checkbox" onChange={handleCheck} name="deleteCheck" />
          <label for="deleteCheck">
            I am certain I want to delete this project. I understand this action
            cannot be undone.
          </label>
        </div>
        <button
          type="button"
          className={editStyle.deleteButton}
          disabled={!checked}
          onClick={handleDelete}
        >
          {" "}
          Delete Project{" "}
        </button>
      </div>
    </>
  );
}

function EditProjectPage() {
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const projectId = useParams().projectId;
  const [imageChanging, setImageChanging] = useState(false);
  const { user, setUser } = useUser();
  const [userRole, setUserRole] = useState("");
  const [removedMembers, setRemovedMembers] = useState([]);
  const [formData, setFormData] = useState({
    ownerId: "",
    title: "",
    description: "",
    meetingFormat: [],
    tags: [],
    status: null,
    usersId: [],
    userRoles: {},
    userTitles: {},
    joinRequests: [],
    imageId: null,
    imageGalleryList: [],
  });
  const [tempGalleryPreviews, setTempGalleryPreviews] = useState([]);
  const [uploadingGallery, setUploadingGallery] = useState(false);

  useEffect(() => {
    return () => {
      // Clean up object URLs
      tempGalleryPreviews.forEach(preview => {
        URL.revokeObjectURL(preview.previewUrl);
      });
    };
  }, [tempGalleryPreviews]);

  //    console.log(projectId);

  useEffect(() => {
    api
      .get(`/projects/${projectId}`)
      .then((response) => {
        setFormData({
          ...response.data,
          imageGalleryList: response.data.imageGalleryList || [] // Ensure array
        });
        if (
          response.data.usersId &&
          user &&
          response.data.usersId.includes(user.id)
        ) {
          return api.get(`users/${user.id}/role/${projectId}`, {
            withCredentials: true,
          });
        } else {
          navigate(`/projects/${projectId}`);
        }
      })
      .then((response) => {
        if (response) {
          setUserRole(response.data);
          if (response.data != "Owner" && response.data != "Admin") {
            navigate(`/projects/${projectId}`);
          }
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data:", error);
      });
  }, [projectId, user, location]);

  const HandleCancel = (e) => {
    //console.log("cancel button pressed");
    navigate(`/projects/${formData.id}`);
    window.location.reload();
  };

  const handleMemberRoleChange = (e) => {
    //console.log(e.target.selectedOptions[0].value); //id of member being changed
    //console.log(e.target.selected); //new selected role
    setFormData((prevData) => {
      const newState = Object.assign({}, prevData);
      newState.userRoles[e.target.id] = e.target.selectedOptions[0].value;
      console.log(newState);
      return newState;
    });
  };

  const handleMemberTitleChange = (e) => {
    //console.log(e.target.id);
    //console.log(e.target.value);
    setFormData((prevData) => {
      const newState = Object.assign({}, prevData);
      newState.userTitles[e.target.id] = e.target.value;
      //console.log(newState);
      return newState;
    });
  };

  const handleMemberRemove = (e) => {
    //console.log(e);
    //console.log("user Roles: ", formData.userRoles);
    setFormData((prevData) => {
      const newState = Object.assign({}, prevData);
      newState.usersId = newState.usersId.filter((id) => id !== e.target.id);
      delete newState.userRoles[e.target.id];
      //console.log(newState);
      delete newState.userTitles[e.target.id];
      //console.log(newState);
      return newState;
    });
    console.log(e.target.id);
    if (typeof removedMembers == undefined) {
      setRemovedMembers([]);
    }
    setRemovedMembers([...removedMembers, e.target.id]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.tags.length < 1 || formData.tags.length > 10) {
      toast.error("Include at least 1 and at most 10 project tags.");
      return;
    }
    
    // Wait for any ongoing uploads to complete
    if (uploadingGallery) {
      toast.info("Please wait for image uploads to complete");
      return;
    }
  
    const newImageIds = tempGalleryPreviews
      .filter(p => p.isUploaded)
      .map(p => p.imageId);
  
    try {
      const updatedFormData = {
        ...formData,
        imageGalleryList: [...formData.imageGalleryList, ...newImageIds],
      };
  
      // removed members
      for (let i in removedMembers) {
        try {
          await api.delete(`/users/${removedMembers[i]}/deleteProject/${formData.id}`);
        } catch (error) {
          console.error("Error removing project from user's list:", error);
        }
      }
      // Update project
      const response = await api.put(`/projects/${formData.id}`, updatedFormData);
      if (response.status === 200 || response.status === 201) {
        toast.success("Project updated successfully");
        navigate(`/projects/${formData.id}`);
      }
      // Clear temp previews after update project
      setTempGalleryPreviews([]);
      
    } catch (error) {
      console.error("Error updating project:", error);
    }
  };


  const handleRemoveImage = () => {
    setFormData((prevData) => ({
      ...prevData,
      imageId: null,
    }));
  };

  const handleInputChange = async (e) => {
    const { name, value, type, files } = e.target;

    // If it's the image field, handle the image upload separately
    if (name === "image" && files && files[0]) {
      try {
        setIsUploading(true);
        // Create a FormData object to send the file
        const formDataImage = new FormData();
        formDataImage.append("file", files[0]);

        // Send image to backend
        const response = await api.post("/images/upload", formDataImage, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        // If image upload is successful, update formData with the imageId
        if (response.status === 200 || response.status === 201) {
          setFormData((prevData) => ({
            ...prevData,
            imageId: response.data,
          }));
          setImageChanging(false);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }finally {
        setIsUploading(false);
      }
    } else {
      if (name === "status") {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value === "true", // Convert the value to boolean
        }));
      } else if (name === "meetingFormat") {
        // console.log(value);
        setFormData((prevData) => ({
          ...prevData,
          [name]: [value],
        }));
        // console.log(formData);
      } else {
        // Handle regular input changes
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };

  const handleGalleryUpload = async (e) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;
  
    const currentCount = (formData.imageGalleryList?.length || 0) + tempGalleryPreviews.length;
    const remainingSlots = 5 - currentCount;
  
    if (remainingSlots <= 0) {
      toast.info("You've reached the maximum of 5 gallery images");
      return;
    }
  
    const filesToAdd = Array.from(files).slice(0, remainingSlots);
  
    // Create preview URLs immediately
    const previews = filesToAdd.map(file => ({
      id: `preview-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
      file,
      previewUrl: URL.createObjectURL(file),
      isUploaded: false
    }));
  
    setTempGalleryPreviews(prev => [...prev, ...previews]);
    e.target.value = ''; // Reset input
  
    // Upload each file
    setUploadingGallery(true);
    try {
      for (const preview of previews) {
        try {
          const formDataImage = new FormData();
          formDataImage.append("file", preview.file);
  
          const response = await api.post("/images/upload", formDataImage, {
            headers: { "Content-Type": "multipart/form-data" },
          });
  
          // Update the preview to show it's uploaded
          setTempGalleryPreviews(prev => 
            prev.map(p => 
              p.id === preview.id 
                ? { ...p, isUploaded: true, imageId: response.data } 
                : p
            )
          );
        } catch (error) {
          console.error("Error uploading image:", error);
          toast.error(`Failed to upload ${preview.file.name}`);
          // Remove the failed upload from previews
          setTempGalleryPreviews(prev => prev.filter(p => p.id !== preview.id));
        }
      }
    } finally {
      setUploadingGallery(false);
    }
  };
  
  const handleRemoveGalleryImage = (index) => {
    setFormData(prevData => {
      const newGallery = [...prevData.imageGalleryList];
      newGallery.splice(index, 1);
      return { ...prevData, imageGalleryList: newGallery };
    });
  };
  
  const handleRemovePreview = (id) => {
    setTempGalleryPreviews(prev => prev.filter(p => p.id !== id));
  };

  return (
    <>
      <div className={style.projectHeader}>
        <EditImage
            handleInputChange={handleInputChange}
            handleRemoveImage={handleRemoveImage}
            imageId={formData.imageId}
            imageChanging={imageChanging}
            setImageChanging={setImageChanging}
          />
      </div>
      <div class={style.projectPageContent}>
        <div className={editStyle.nameColumn}>
          <div className={editStyle.nameRow}>
          <label className={editStyle.fieldLabel} htmlFor="title">
            Project name:
          </label>
            <CharacterCounterInput
              type="input"
              className={editStyle.projectTitleEntry}
              name="title"
              id="title"
              maxChars={60}
              value={formData.title}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div>
          <Members
            usersId={formData.usersId}
            userRoles={formData.userRoles}
            userTitles={formData.userTitles}
            handleMemberRoleChange={handleMemberRoleChange}
            handleMemberTitleChange={handleMemberTitleChange}
            handleMemberRemove={handleMemberRemove}
          />
        </div>
        <div>
        <ImageGalleryUpload
          galleryImages={formData.imageGalleryList || []} // Fallback to empty array
          tempPreviews={tempGalleryPreviews}
          handleGalleryUpload={handleGalleryUpload}
          handleRemoveGalleryImage={handleRemoveGalleryImage}
          handleRemovePreview={handleRemovePreview}
          uploading={uploadingGallery}
        />
        </div>
        <div class={style.aboutSection}>
          <div class={editStyle.aboutSection}>
            <h3>About Us</h3>
            <textarea
              class={editStyle.largeTextEntry}
              name="description"
              id="description"
              required
              maxLength="2000"
              onChange={handleInputChange}
              value={formData.description}
            ></textarea>
          </div>
        </div>
        <div>
          <TagSelector
            formData={formData}
            setFormData={setFormData}
            formDataField={"tags"}
          />
        </div>
        <div>
          <h3>Meeting Format</h3>
          <div class={editStyle.meetingFormatOptions}>
            <label>
              <input
                type="radio"
                name="meetingFormat"
                value="In-Person"
                required
                className={editStyle.radio}
                checked={formData.meetingFormat == "In-Person"}
                onChange={handleInputChange}
              />
              In-Person
            </label>
            <label>
              <input
                type="radio"
                name="meetingFormat"
                value="Virtual"
                required
                className={editStyle.radio}
                checked={formData.meetingFormat == "Virtual"}
                onChange={handleInputChange}
              />
              Virtual
            </label>
            <label>
              <input
                type="radio"
                name="meetingFormat"
                value="Hybrid"
                required
                className={editStyle.radio}
                checked={formData.meetingFormat == "Hybrid"}
                onChange={handleInputChange}
              />
              Hybrid
            </label>
          </div>
        </div>
        <div>
          <h3>Recruiting Status</h3>
          <div className={editStyle.statusCheck}>
            <label>
              <input
                type="radio"
                name="status"
                value={true}
                className={editStyle.radio}
                checked={formData.status === true}
                onChange={handleInputChange}
              />
              Looking for new members
            </label>
            <label>
              <input
                type="radio"
                name="status"
                value={false}
                className={editStyle.radio}
                checked={formData.status === false}
                onChange={handleInputChange}
              />
              Not looking for new members
            </label>
          </div>
        </div>
        <div class={editStyle.formButtons}>
          <button
            className={editStyle.cancelButton}
            onClick={HandleCancel}
            type="button"
          >
            Cancel Changes
          </button>
          <button
            className={editStyle.submitButton}
            onClick={handleSubmit}
            type="button"
            disabled={isUploading}
          >
            {isUploading ? "Uploading Image..." : "Update Project"}
          </button>
        </div>
        {userRole == "Owner" ? (
          <div class={editStyle.deleteProjectSection}>
            <DeleteProjectSection projectID={formData.id} />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default EditProjectPage;
