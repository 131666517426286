import React from "react";
import editStyle from "../styles/modules/EditProjectPage.module.css";
import Image from "../components/Image";

function ImageGalleryUpload({
    galleryImages = [], // Default to empty array if undefined
    tempPreviews = [], // Default to empty array if undefined
    handleGalleryUpload,
    handleRemoveGalleryImage,
    handleRemovePreview,
    uploading
  }) {
    const totalImages = galleryImages.length + tempPreviews.length;
    const remainingSlots = 5 - totalImages;
  
    return (
      <div className={editStyle.gallerySection}>
        <h3>Project Gallery (Max 5 images)</h3>
        <div className={editStyle.galleryPreview}>
          {/* Existing uploaded images */}
          {galleryImages.map((imageId, index) => (
            <div key={`uploaded-${imageId}`} className={editStyle.galleryImageWrapper}>
              <Image id={imageId} styleType={editStyle.galleryImage} />
              <button
                type="button"
                className={editStyle.removeGalleryButton}
                onClick={() => handleRemoveGalleryImage(index)}
              >
                ×
              </button>
            </div>
          ))}
  
          {/* Temporary previews */}
          {tempPreviews.map((preview) => (
            <div key={preview.id} className={editStyle.galleryImageWrapper}>
              <img 
                src={preview.previewUrl} 
                className={editStyle.galleryImage} 
                alt="Preview" 
              />
              <div className={editStyle.uploadStatus}>
                {preview.isUploaded ? '✓' : 'uploading'}
              </div>
              <button
                type="button"
                className={editStyle.removeGalleryButton}
                onClick={() => handleRemovePreview(preview.id)}
              >
                ×
              </button>
            </div>
          ))}
        </div>
  
        {remainingSlots > 0 && (
          <div className={editStyle.galleryUpload}>
            <label className={editStyle.galleryUploadLabel}>
              <span>
                {uploading ? 'Uploading...' : `Add ${remainingSlots} more image${remainingSlots > 1 ? 's' : ''}`}
              </span>
              <input
                type="file"
                multiple
                accept="image/*"
                onChange={handleGalleryUpload}
                disabled={uploading}
                style={{ display: 'none' }}
              />
            </label>
          </div>
        )}
      </div>
    );
  }
  export default ImageGalleryUpload;