import React, { useState } from "react";
import ShowPasswordButton from "../components/ShowPasswordButton";
import { useNavigate } from "react-router-dom";
import style from "../styles/modules/AuthPages.module.css";
import logo from "../assets/IconWhite.png";
import api from "../config/AxiosConfig";

function RegisterPage() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = [];
    if (
      !(
        formData.email.toLowerCase().endsWith("@utah.edu") ||
        formData.email.toLowerCase().endsWith("@umail.utah.edu")
      )
    ) {
      errors.push("Email must be a University of Utah email address.");
    }
    if (formData.password !== formData.confirmPassword) {
      errors.push("Passwords do not match!");
    }
    if (formData.password.length > 72) {
      errors.push("Password is too long!");
    }

    if (errors.length > 0) {
      alert(errors.join("\n"));
      return;
    }

    try {
      const response = await api.post("public/auth/register", {
        email: formData.email,
        password: formData.password,
      });
      if (response.status === 200 || response.status === 201) {
        alert(
          "Registration successful! Check your email for a verification link before signing in."
        );
        const params = new URLSearchParams(window.location.search);
        const redirectPath = params.get("redirect") || "/search";
        navigate(`/signin?redirect=${encodeURIComponent(redirectPath)}`);
      }
    } catch (e) {
      const errorMessage =
        e.response?.data || "An error occurred during registration.";
      const statusCode = e.response?.status;
      alert(`Error ${statusCode}: ${errorMessage}`);
    }
  };

  return (
    <>
      <div class={style.container}>
        <img class={style.logoImage} src={logo} alt="FindU Logo"></img>
        <form class={style.entryArea} onSubmit={handleSubmit}>
          <h1 class={style.formTitle}>Register</h1>
          <table class={style.inputTable}>
            <tbody class={style.inputTableBody}>
              <tr class={style.columnTr}>
                <td>
                  <label class={style.fieldLabel} for="email">
                    University of Utah Email:
                  </label>
                </td>
                <td>
                  <input
                    class={style.formInput}
                    id="email"
                    name="email"
                    placeholder="u0000000@utah.edu"
                    autoFocus
                    required
                    value={formData.email}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr class={style.columnTr}>
                <td>
                  <label class={style.fieldLabel} for="password">
                    Password:
                  </label>
                </td>
                <td class={style.rowTd}>
                  <input
                    class={style.formInput}
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Enter a password"
                    required
                    value={formData.password}
                    onChange={handleChange}
                  />
                  <ShowPasswordButton id="password" />
                </td>
              </tr>
              <tr class={style.columnTr}>
                <td>
                  <label class={style.fieldLabel} for="confirmPassword">
                    Confirm password:
                  </label>
                </td>
                <td class={style.rowTd}>
                  <input
                    class={style.formInput}
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    placeholder="Enter a password"
                    required
                    value={formData.confirmPassword}
                    onChange={handleChange}
                  />
                  <ShowPasswordButton id="confirmPassword" />
                </td>
              </tr>
            </tbody>
            <tfoot class={style.footer}>
              <td>
                <button class={style.submitButton}>Continue</button>
              </td>
            </tfoot>
          </table>
          <p>
            Already have an account?{" "}
            <a class={style.redirectLink} href="/signin/">
              Sign in here.
            </a>
          </p>
        </form>
      </div>
      <div class={style.background}></div>
    </>
  );
}

export default RegisterPage;
