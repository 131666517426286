import React, { useEffect, useState } from "react";
import api from "../config/AxiosConfig";

function EmailVerificationPage() {
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");

    if (token) {
      api
        .get(`/verify-email?token=${token}`, { signal })
        .then((response) => {
          const successMessage =
            response.data?.message || "Verification successful!";
          setStatus({ success: true, message: successMessage });
        })
        .catch((error) => {
          if (error.name === "CanceledError") {
            console.log("Request canceled");
            return;
          }
          const errorMessage =
            error.response?.data?.error ||
            "There was a problem verifying your email.";
          setStatus({ success: false, message: errorMessage });
        });
    } else {
      setStatus({ success: false, message: "No verification token provided." });
    }

    return () => {
      // Cancel any ongoing requests
      controller.abort();
    };
  }, []);

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      {status ? (
        status.success ? (
          <div style={{ color: "green" }}>
            <h2>
              Email Verified Successfully! You can now exit this page and{" "}
              <a href="https://www.findu.online/signin">sign in</a>.
            </h2>
            <p>{status.message}</p>
          </div>
        ) : (
          <div style={{ color: "red" }}>
            <h2>Email Verification Failed</h2>
            <p>{status.message}</p>
          </div>
        )
      ) : (
        <h2>Verifying your email, please wait...</h2>
      )}
    </div>
  );
}

export default EmailVerificationPage;
