import "./App.css";
import {
  BrowserRouter,
  Navigate,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { FormProvider } from "./context/FormContext";
import SearchPage from "./pages/SearchPage";
import UserPage from "./pages/UserPage";
import ProjectPage from "./pages/ProjectPage";
import SignInPage from "./pages/SignInPage";
import RegisterPage from "./pages/RegisterPage";
import HomePage from "./pages/HomePage";
import EmailVerificationPage from "./pages/EmailVerificationPage";
import TopNav from "./components/TopNav";
import CreateProjectPage from "./pages/CreateProjectPage";
import CreateUserPage from "./pages/CreateUserPage";
import NotificationPage from "./pages/NotificationPage";
import EditProjectPage from "./pages/EditProjectPage";
import { UserProvider } from "./context/UserContext";
import LandingPage from "./pages/LandingPage";
import EditUserPage from "./pages/EditUserPage";
import Footer from "./components/Footer";
import NotFoundPage from "./pages/NotFoundPage";
import ChatPage from "./pages/ChatPage";
import { ToastContainer, Slide } from "react-toastify";
import PasswordRecoveryPage from "./pages/PasswordRecoveryPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import SettingsPage from "./pages/SettingsPage";
import { ThemeProvider } from "./context/ThemeContext";
import AddEventPage from "./pages/AddEventPage";

function ErrorPage() {
  return (
    <>
      <h1>An error has occurred</h1>
      <p>Please contact the site administrator for support.</p>
    </>
  );
}

//exclude landing page from applying MainContent style
const Layout = ({ children }) => {
  const location = useLocation();
  const isLandingPage = location.pathname === "/"; // Check if it's the landing page

  return <div className={isLandingPage ? "" : "MainContent"}>{children}</div>;
};

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <UserProvider>
          <FormProvider>
            <TopNav />
            <ToastContainer
              position="top-center"
              autoClose={3000}
              hideProgressBar
              transition={Slide}
            />
            <main>
              <Layout>
                <Routes>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/search" element={<SearchPage />} />
                  <Route
                    path="/projects/:projectId"
                    element={<ProjectPage />}
                  />
                  <Route
                    path="/projects/:projectId/edit"
                    element={<EditProjectPage />}
                  />
                  <Route
                    path="/projects/:projectId/addEvent"
                    element={<AddEventPage/>}
                  />
                  <Route
                    path="/createproject"
                    element={<CreateProjectPage />}
                  />
                  <Route path="/users/:userId" element={<UserPage />} />
                  <Route
                    path="/users/:userId/edit"
                    element={<EditUserPage />}
                  />
                  <Route path="/createuser" element={<CreateUserPage />} />
                  <Route path="/signin" element={<SignInPage />} />
                  <Route path="/register" element={<RegisterPage />} />
                  <Route
                    path="/forgot-password"
                    element={<PasswordRecoveryPage />}
                  />
                  <Route
                    path="/reset-password"
                    element={<ResetPasswordPage />}
                  />
                  <Route path="/notifications" element={<NotificationPage />} />
                  <Route path="/chats" element={<ChatPage />} />
                  <Route path="/chats/:chatId" element={<ChatPage />} />
                  <Route
                    path="/verify-email"
                    element={<EmailVerificationPage />}
                  />
                  <Route
                    path="/users/:userId/settings"
                    element={<SettingsPage />}
                  />
                  <Route path="/error" element={<ErrorPage />} />
                  <Route path="/error/404" element={<NotFoundPage />} />
                  <Route path="*" element={<Navigate to="/error/404" />} />
                </Routes>
              </Layout>
            </main>
            <Footer />
            <div className="mainBackground"></div>
          </FormProvider>
        </UserProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
