//display type long: Today, 6:42 PM or Yesterday, 3:23 AM or Feb 19th, 4:23 AM or 2/4/21, 10:24 PM
//display type short: 6:42 PM or Yesterday or Feb 19th or 2/4/21
//display type relative: just now or 5 minutes ago or 1 hour ago or 2 weeks ago or 412 weeks ago

import React, { useEffect, useState } from "react";

function Timestamp({timeGiven, displayType}) {
    var timeMessage = "";
    var date = "";
    var time = "";
    var short = "";

    const [now, setNow] = useState(new Date(Date.now()))

    useEffect(() => {
        setNow(new Date(Date.now()));
    }, [timeGiven])

//    console.log(timeGiven);

    if (!timeGiven) {
        return (<></>);
    }

    var trimmedTime = timeGiven;
    if (timeGiven[0] == "\"" && timeGiven[timeGiven.length-1] == "\"") {
        trimmedTime = timeGiven.substring(1, timeGiven.length-1);
    }

    const given = new Date(Date.parse(trimmedTime));
//    const now = new Date(Date.now());

//    console.log(given);

    const diffTime = Math.abs(now - given); // in milliseconds
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const diffWeeks = Math.floor(diffDays / 7);
    const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
    const diffMins = Math.floor(diffTime / (1000 * 60));

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

    time = given.toLocaleTimeString("en-US")
    if (given.getHours() > 12) {
        if (given.getMinutes() > 9) {
            time = (given.getHours()-12) + ":" + given.getMinutes() + " PM";
        }
        else {
            time = (given.getHours()-12) + ":0" + given.getMinutes() + " PM";
        }
    }
    else if (given.getHours() == 0) {
        if (given.getMinutes() > 9) {
            time = "12:" + given.getMinutes() + " AM";
        }
        else {
            time = "12:0" + given.getMinutes() + " AM";
        }
    }
    else {
        if (given.getMinutes() > 9) {
            time = given.getHours() + ":" + given.getMinutes() + " AM";
        }
        else {
            time = given.getHours() + ":0" + given.getMinutes() + " AM";
        }
    }

    short = time;
    date = "Today";

    if (given.getFullYear() != now.getFullYear()) {
        date = (given.getMonth()+1) + "/" + (given.getDate()) + "/" + (given.getYear().toString().substring(2))
        short = date;
    }
    else if (diffWeeks > 1) {
        date = months[given.getMonth()] + " " + (given.getDate())
        short = date;
        timeMessage = diffWeeks + " weeks ago";
    }
    else if (diffDays > 1) {
        date = months[given.getMonth()] + " " + (given.getDate())
        short = date;
        timeMessage = diffDays + " days ago";
    }
    else if (diffDays == 1 || (now.getDate() != given.getDate())) {
        date = "Yesterday"
        short = date;
        timeMessage = "1 day ago";
    }
    else if (diffHours > 1) {
        timeMessage = diffHours + " hours ago";
    }
    else if (diffHours == 1) {
        timeMessage = "1 hour ago";
    }
    else if (diffMins > 1) {
        timeMessage = diffMins + " minutes ago";
    } else {
        timeMessage = "just now";
    }

    if (displayType=="short" || (displayType=="medium" && date == "Today")) {
        timeMessage = short;
    }
    else if (displayType == "long" || displayType=="medium") {
        timeMessage = date + ", " + time;
    }

    return(<>{timeMessage}</>)
}

export default Timestamp;