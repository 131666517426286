import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import style from "../styles/modules/AuthPages.module.css";
import logo from "../assets/IconWhite.png";
import api from "../config/AxiosConfig";
import { toast } from "react-toastify";

function PasswordRecoveryPage() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    recoveryCode: Array(6).fill(""),
  });
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [timer, setTimer] = useState(30);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    let interval;
    if (isCodeSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsButtonDisabled(false);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isCodeSent, timer]);

  // Super fancy input handler to make the code entry smoooooooth *o*
  // Side note - still a little broke when deleting and re-entering values
  const handleChange = (e, index) => {
    const { value } = e.target;
    const newCode = [...formData.recoveryCode];

    // If the user deletes the character, focus on the previous input
    if (value === "") {
      newCode[index] = ""; // Update state to reflect the deletion
      setFormData((prevState) => ({ ...prevState, recoveryCode: newCode }));

      // Move focus to the previous input if the user deletes the character
      if (index > 0) {
        document.getElementById(`recoveryCode${index - 1}`).focus();
      }
    } else {
      // Otherwise, just update the character and move to the next input
      newCode[index] = value.toUpperCase(); // Convert the value to uppercase
      setFormData((prevState) => ({ ...prevState, recoveryCode: newCode }));

      // Move to the next input field if the user has typed something
      if (value && index < 5) {
        document.getElementById(`recoveryCode${index + 1}`).focus();
      }
    }
  };

  const handleSendCode = async (e) => {
    //make one api call that checks the email exists, sets a code to the account, and sends the email
    try {
      const response = await api.put("public/auth/password-recovery", {
        email: formData.email,
      });

      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status == 204
      ) {
        setIsCodeSent(true);
        setIsButtonDisabled(true);
        setTimer(30); // Reset the timer back to 30 seconds
        toast.success("Recovery code has been sent to your email.");
      }
    } catch (e) {
      const errorMessage =
        e.response?.data ||
        "An error occurred during recovery code creation. Please try again.";
      const statusCode = e.response?.status || "undefined error";
      toast.error(`Error ${statusCode}: ${errorMessage}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = [];

    // Join the recovery code array into a string for validation
    const token = formData.recoveryCode.join("");
    if (token.length !== 6) {
      errors.push("Recovery code must be 6 characters.");
    }

    if (errors.length > 0) {
      toast.error(errors.join("\n"));
      return;
    }

    try {
      const response = await api.post("public/auth/verify-recovery-token", {
        email: formData.email,
        token: token,
      });

      if (response.status === 200 || response.status === 201) {
        navigate(`/reset-password?user=${response.data}&token=${token}`);
      }
    } catch (e) {
      const errorMessage =
        e.response?.data || "An error occurred during password recovery.";
      const statusCode = e.response?.status;
      toast.error(`Error ${statusCode}: ${errorMessage}`);
    }
  };

  return (
    <>
      <div className={style.container}>
        <img className={style.logoImage} src={logo} alt="FindU Logo" />
        <form className={style.entryArea} onSubmit={handleSubmit}>
          <h1 className={style.formTitle}>Reset Your Password</h1>
          <table className={style.inputTable}>
            <tbody className={style.inputTableBody}>
              <tr className={style.columnTr}>
                <td>
                  <label className={style.fieldLabel} htmlFor="email">
                    Email address used to sign up:
                  </label>
                </td>
                <td>
                  <input
                    className={style.formInput}
                    id="email"
                    name="email"
                    placeholder="Enter the email used when signing up"
                    required
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </td>
                <td>
                  <button
                    className={style.sendCodeButton}
                    type="button"
                    onClick={handleSendCode}
                    disabled={isButtonDisabled}
                  >
                    {isButtonDisabled
                      ? `Please wait ${timer}s`
                      : "Send Recovery Code"}
                  </button>
                </td>
              </tr>

              {isCodeSent && (
                <tr className={style.columnTr}>
                  <td>
                    <label className={style.fieldLabel} htmlFor="recoveryCode">
                      Enter Recovery Code:
                    </label>
                  </td>
                  <div className={style.codeInputs}>
                    {formData.recoveryCode.map((digit, index) => (
                      <input
                        key={index}
                        className={style.codeInput}
                        type="text"
                        maxLength="1"
                        value={digit}
                        onChange={(e) => handleChange(e, index)}
                        id={`recoveryCode${index}`}
                      />
                    ))}
                  </div>
                </tr>
              )}
            </tbody>
            {isCodeSent && (
              <tfoot className={style.footer}>
                <td>
                  <button className={style.submitButton} type="submit">
                    Continue
                  </button>
                </td>
              </tfoot>
            )}
          </table>
        </form>
      </div>
      <div className={style.background}></div>
    </>
  );
}

export default PasswordRecoveryPage;
